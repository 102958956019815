import React, { useEffect, useState } from 'react';
import './css/WebEcommerceSolutions.css';

const WebEcommerceSolutions = ({ language }) => {
  const [slides, setSlides] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [email, setEmail] = useState(''); // Track the email input state
  const [message, setMessage] = useState(''); // Track success/error messages

  // Fetch translations based on the selected language
  useEffect(() => {
    const fetchTranslations = async () => {
      const url = `/languages/${language === 'sv' ? 'swedish' : 'english'}.json`;

      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`Failed to fetch translations: ${response.status}`);
        }

        const data = await response.json();
        // Define slides using truncated data
        setSlides([
          {
            img: `/assets/images/custom_ecommerce_development.jpg`,
            title: 'Anpassad E-handelsutveckling',
            text: 'Ge ditt företag kraft med skräddarsydda e-handelslösningar designade för att växa. Från intuitiva användarupplevelser till säkra betalningslösningar, skapar vi webbutiker som engagerar kunder och ökar försäljningen.',
            button: 'Läs Mer',
          },
          {
            img: `/assets/images/seamless_ux_design.jpg`,
            title: 'seemless Användarupplevelse (UX) Design',
            text: 'Förbättra kundnöjdhet och lojalitet med vår användarcentrerade UX-design. Vi skapar gränssnitt som erbjuder smidig navigering för att ge dina besökare en enkel shoppingupplevelse.',
            button: 'Utforska Nu',
          },
          {
            img: `/assets/images/mobile_optimized_ecommerce.jpg`,
            title: 'Mobiloptimerade E-handelsplattformar',
            text: 'I dagens mobilfokuserade värld bygger vi responsiva e-handelsplattformar optimerade för smartphones och surfplattor, så att dina kunder enkelt kan handla när de är på språng.',
            button: 'Shoppa Nu',
          },
          {
            img: `/assets/images/scalable_web_platforms.jpg`,
            title: 'Skalbara Webbplattformar',
            text: 'Ditt företag växer, och det borde även din webbplattform göra. Vi utvecklar skalbara webblösningar som kan anpassas i takt med att din verksamhet expanderar.',
            button: 'Läs Mer',
          },
          {
            img: `/assets/images/advanced_ecommerce_integrations.jpg`,
            title: 'Avancerade E-handelsintegrationer',
            text: 'Våra lösningar integreras seemlesst med olika tredjepartstjänster, vilket garanterar att ditt e-handelssystem fungerar smidigt och effektivt.',
            button: 'Utforska Nu',
          },
          {
            img: `/assets/images/secure_payment_solutions.jpg`,
            title: 'Säkra Betallösningar',
            text: 'Säkerhet är kärnan i våra e-handelsplattformar. Vi implementerar säkra och krypterade betallösningar som skyddar dina kunder och säkerställer smidiga transaktioner.',
            button: 'Shoppa Nu',
          },
          {
            img: `/assets/images/ai_driven_personalization.jpg`,
            title: 'AI-drivna Personaliseringar för E-handel',
            text: 'Utnyttja kraften i AI för att leverera personliga shoppingupplevelser. Våra AI-lösningar analyserar kundbeteenden och rekommenderar produkter.',
            button: 'Läs Mer',
          },
          {
            img: `/assets/images/custom_web_portals.jpg`,
            title: 'Anpassade Webbportaler för B2B & B2C',
            text: 'Vi bygger funktionsrika webbportaler för B2B- och B2C-företag. Effektiv hantering av produktkataloger och fleranvändaråtkomst.',
            button: 'Utforska Nu',
          },
          {
            img: `/assets/images/omnichannel_strategy.jpg`,
            title: 'Omnichannel E-handelsstrategi',
            text: 'Nå dina kunder var de än befinner sig. Våra strategier integrerar webb, mobil och sociala plattformar för en enhetlig shoppingupplevelse.',
            button: 'Läs Mer',
          },
          {
            img: `/assets/images/high_performance_hosting.jpg`,
            title: 'Högpresterande Hosting & Underhåll',
            text: 'Håll din webbplats snabb, säker och pålitlig med våra hosting- och underhållslösningar. Vi ser till att din webbutik alltid körs på toppnivå.',
            button: 'Shoppa Nu',
          },
        ]);
      } catch (error) {
        console.error('Error fetching translations for WebEcommerceSolutions:', error);
      }
    };

    fetchTranslations();
  }, [language]);

  // Automatic slide transition
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) =>
        prevSlide === slides.length - 1 ? 0 : prevSlide + 1
      );
    }, 10000); // Change slide every 10 seconds

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [slides.length]);

  // Manual slide navigation
  const moveSlide = (direction) => {
    if (direction === 'next') {
      setCurrentSlide((prevSlide) =>
        prevSlide === slides.length - 1 ? 0 : prevSlide + 1
      );
    } else if (direction === 'prev') {
      setCurrentSlide((prevSlide) =>
        prevSlide === 0 ? slides.length - 1 : prevSlide - 1
      );
    }
  };

  // Handle email registration (you can connect this to an API)
  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    if (!email) {
      setMessage('Please enter a valid email.');
      return;
    }

    try {
      // Simulate an API request to register the email (you'll replace this with actual API)
      const response = await fetch('/api/register-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (!response.ok) {
        throw new Error('Failed to register email');
      }

      setMessage('Email successfully registered!');
      setEmail(''); // Clear email input after submission
    } catch (error) {
      console.error('Error registering email:', error);
      setMessage('Failed to register email.');
    }
  };

  return (
    <div className="WebEcommerceSolutions-container">
      <div className="WebEcommerceSolutions" style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
        {slides.map((slide, index) => (
          <div className="slide" key={index}>
            <div className="slide-image-container">
              <img src={slide.img} alt={slide.title} className="slide-image" />
            </div>
            <div className="slide-text-container">
              <h2>{slide.title}</h2>
              <p>{slide.text}</p>
              {slide.input && (
                <form onSubmit={handleEmailSubmit}>
                  <input
                    type="email"
                    placeholder="Enter your email"
                    className="email-input"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <button type="submit" className="submit-email-button">
                    Submit
                  </button>
                </form>
              )}
              {message && <p className="email-message">{message}</p>}
              {slide.button && (
                <button className="shop-now-button">{slide.button}</button>
              )}
            </div>
          </div>
        ))}
      </div>
      <button className="prev" onClick={() => moveSlide('prev')}>
        &#10094;
      </button>
      <button className="next" onClick={() => moveSlide('next')}>
        &#10095;
      </button>
    </div>
  );
};

export default WebEcommerceSolutions;
/*For **"Web & E-commerce Solutions"**, here are some interesting concepts and ideas you could explore, which focus on the evolving needs of businesses and users:

### 1. **Title: Custom E-commerce Development**
   **Description**: 
   "Empower your business with tailor-made e-commerce solutions designed to scale. From intuitive user experiences to secure payment gateways, we create online stores that engage customers and drive sales."

### 2. **Title: Seamless User Experience (UX) Design**
   **Description**: 
   "Enhance customer satisfaction and retention with our user-centric UX designs. We build interfaces that are not only visually appealing but also offer seamless navigation, ensuring your visitors have a smooth shopping experience."

### 3. **Title: Mobile-Optimized E-commerce Platforms**
   **Description**: 
   "In today's mobile-first world, we build responsive e-commerce platforms optimized for smartphones and tablets, ensuring that your customers can shop on-the-go with ease."

### 4. **Title: Scalable Web Solutions**
   **Description**: 
   "Your business is growing, and so should your web platform. We develop scalable web solutions that can evolve with your business, whether it's adding new features, handling more traffic, or expanding to new markets."

### 5. **Title: Advanced E-commerce Integrations**
   **Description**: 
   "Our solutions integrate seamlessly with various third-party services, including CRMs, ERPs, and marketing automation tools, ensuring your e-commerce ecosystem runs smoothly and efficiently."

### 6. **Title: Secure Payment Solutions**
   **Description**: 
   "Security is at the heart of our e-commerce platforms. We implement secure, encrypted payment solutions that protect your customers and ensure smooth, error-free transactions."

### 7. **Title: AI-Driven Personalization for E-commerce**
   **Description**: 
   "Leverage the power of artificial intelligence to deliver personalized shopping experiences. Our AI-driven solutions analyze customer behavior and recommend products that match their interests, boosting conversion rates."

### 8. **Title: Custom Web Portals for B2B & B2C**
   **Description**: 
   "We build feature-rich web portals for B2B and B2C businesses. From streamlined product catalog management to multi-level user access, our solutions help you manage your digital operations efficiently."

### 9. **Title: Omnichannel E-commerce Strategy**
   **Description**: 
   "Reach your customers wherever they are. Our omnichannel strategies integrate web, mobile, and social platforms, allowing for a unified and seamless shopping experience across all touchpoints."

### 10. **Title: High-Performance Hosting & Maintenance**
   **Description**: 
   "Keep your website fast, secure, and reliable with our high-performance hosting and ongoing maintenance solutions. We ensure your online store is always running at peak performance, 24/7."

---

Would you like to explore more details on any specific type of web and e-commerce solutions, or are there other focus areas you'd like to dive into?




Here’s the Swedish translation for the **"Web & E-commerce Solutions"** concepts:

### 1. **Titel: Anpassad E-handelsutveckling**
   **Beskrivning**: 
   "Ge ditt företag kraft med skräddarsydda e-handelslösningar designade för att växa. Från intuitiva användarupplevelser till säkra betalningslösningar, skapar vi webbutiker som engagerar kunder och ökar försäljningen."

### 2. **Titel: seemless Användarupplevelse (UX) Design**
   **Beskrivning**: 
   "Förbättra kundnöjdhet och lojalitet med vår användarcentrerade UX-design. Vi skapar gränssnitt som inte bara är visuellt tilltalande utan också erbjuder smidig navigering för att ge dina besökare en enkel shoppingupplevelse."

### 3. **Titel: Mobiloptimerade E-handelsplattformar**
   **Beskrivning**: 
   "I dagens mobilfokuserade värld bygger vi responsiva e-handelsplattformar optimerade för smartphones och surfplattor, så att dina kunder enkelt kan handla när de är på språng."

### 4. **Titel: Skalbara Webbplattformar**
   **Beskrivning**: 
   "Ditt företag växer, och det borde även din webbplattform göra. Vi utvecklar skalbara webblösningar som kan anpassas i takt med att din verksamhet expanderar, vare sig det gäller att lägga till nya funktioner, hantera ökad trafik eller expandera till nya marknader."

### 5. **Titel: Avancerade E-handelsintegrationer**
   **Beskrivning**: 
   "Våra lösningar integreras seemlesst med olika tredjepartstjänster, inklusive CRM-system, ERP-lösningar och marknadsföringsautomation, vilket garanterar att ditt e-handelssystem fungerar smidigt och effektivt."

### 6. **Titel: Säkra Betallösningar**
   **Beskrivning**: 
   "Säkerhet är kärnan i våra e-handelsplattformar. Vi implementerar säkra och krypterade betallösningar som skyddar dina kunder och säkerställer smidiga och felfria transaktioner."

### 7. **Titel: AI-drivna Personaliseringar för E-handel**
   **Beskrivning**: 
   "Utnyttja kraften i artificiell intelligens för att leverera personliga shoppingupplevelser. Våra AI-drivna lösningar analyserar kundbeteenden och rekommenderar produkter som matchar deras intressen, vilket ökar konverteringsgraden."

### 8. **Titel: Anpassade Webbportaler för B2B & B2C**
   **Beskrivning**: 
   "Vi bygger funktionsrika webbportaler för B2B- och B2C-företag. Från effektiv hantering av produktkataloger till fleranvändaråtkomst på olika nivåer, hjälper våra lösningar dig att hantera dina digitala affärer smidigt."

### 9. **Titel: Omnichannel E-handelsstrategi**
   **Beskrivning**: 
   "Nå dina kunder var de än befinner sig. Våra omnichannel-strategier integrerar webb, mobil och sociala plattformar, vilket möjliggör en enhetlig och smidig shoppingupplevelse över alla kanaler."

### 10. **Titel: Högpresterande Hosting & Underhåll**
   **Beskrivning**: 
   "Håll din webbplats snabb, säker och pålitlig med våra högpresterande hosting- och underhållslösningar. Vi ser till att din webbutik alltid körs på toppnivå, dygnet runt."

---

Let me know if you need further adjustments or additional content!

*/