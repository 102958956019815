// BackgroundSelector.js
import React from 'react';

const BackgroundSelector = ({ setBackgroundImage }) => {
    const backgrounds = [
        `${process.env.PUBLIC_URL}/assets/images/img1.jpg`,
        `${process.env.PUBLIC_URL}/assets/images/img2.jpg`,
        `${process.env.PUBLIC_URL}/assets/images/img3.jpg`,
        `${process.env.PUBLIC_URL}/assets/images/img4.jpg`,
        `${process.env.PUBLIC_URL}/assets/images/img5.jpg`,
        `${process.env.PUBLIC_URL}/assets/images/img6.jpg`,
    ];

    const handleBackgroundChange = (image) => {
        setBackgroundImage(image); // Set the selected background image
    };

    return (
        <div className="backgrounds">
            {backgrounds.map((bg, index) => (
                <img
                    key={index}
                    src={bg}
                    alt={`Background ${index + 1}`}
                    onClick={() => handleBackgroundChange(bg)} // Change background on click
                    style={{
                        cursor: 'pointer',
                        border: '2px solid transparent',
                        width: '100px', // You can adjust the width/height as needed
                        height: '100px',
                        borderRadius: '5px',
                        margin: '5px',
                        opacity: 0.8,
                        transition: 'border 0.3s, opacity 0.3s',
                    }}
                    onMouseOver={(e) => {
                        e.currentTarget.style.opacity = '1';
                    }}
                    onMouseOut={(e) => {
                        e.currentTarget.style.opacity = '0.8';
                    }}
                />
            ))}
        </div>
    );
};

export default BackgroundSelector;