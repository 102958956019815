import React, { useEffect, useState } from 'react';
import apiService from './apiService'; 

const ProductFetcher = ({ productKey, onProductData }) => {
  const [error, setError] = useState(null);

  const apiUrl = `${process.env.REACT_APP_WEBSERVICE_URL}${process.env.REACT_APP_WEBSERVICE_VERSION}${process.env.REACT_APP_WEBSERVICE_GET_CATEGORIES}`;

  useEffect(() => {
    if (productKey) {
      const fetchProduct = async () => {
        try {
          console.log("Fetching product data for key:", productKey);
          const data = await apiService.get(apiUrl);
          onProductData(JSON.stringify(data));
          console.log(JSON.stringify(data))

        } catch (error) {
          setError(error.message);
          console.error('Error fetching product details:', error);
        }
      };

      fetchProduct();
    }
  }, [productKey, onProductData]); // Keep the dependencies as is

  if (error) {
    return <p>Error: {error}</p>;
  }

  return null; // This component does not render anything
};

export default ProductFetcher;