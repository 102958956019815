import React, { useEffect, useState } from 'react';
import './css/PaperPrints.css';

const PaperPrints = ({ language }) => {
  const [slides, setSlides] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [email, setEmail] = useState(''); // Track the email input state
  const [message, setMessage] = useState(''); // Track success/error messages

  // Fetch translations based on the selected language
  useEffect(() => {
    const fetchTranslations = async () => {
      const url = `/languages/${language === 'sv' ? 'swedish' : 'english'}.json`;

      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`Failed to fetch translations: ${response.status}`);
        }

        const data = await response.json();

        // Define slides using translated data
        setSlides([
          {
            img: `/assets/images/poster.jpg`,
            title: 'Poster',
            text: 'Vi erbjuder posters i hög upplösning upp till A3-format med klara färger och skarpa detaljer. Perfekt för marknadsföring och dekoration.',
            button: 'Learn More',
          },
          {
            img: `/assets/images/flyers.jpg`,
            title: 'Flyers och Vykort',
            text: 'Våra flyers och vykort trycks med hög upplösning upp till A3-format och skarpa tryck, perfekt för marknadsföring och evenemang.',
            button: 'Learn More',
          },
          {
            img: `/assets/images/fineart.jpg`,
            title: 'Fine Art Papper',
            text: 'Tryck på exklusiva papper som Scandia 2000 och Conqueror Laid, med hög upplösning upp till A3-format för konsttryck och lyxiga projekt.',
            button: 'Learn More',
          },
          {
            img: `/assets/images/visitkort.jpg`,
            title: 'Visitkort',
            text: 'Professionella visitkort med hög precision, tryckta i hög upplösning och med en exklusiv finish. Perfekt för att lämna ett bestående intryck.',
            button: 'Learn More',
          },
          {
            img: `/assets/images/studentskylt.jpg`,
            title: 'Studentskylt',
            text: 'Högupplösta studentskyltar upp till A3-format, klara och hållbara, levereras snabbt för att passa ditt firande.',
            button: 'Learn More',
          },
        ]);
      } catch (error) {
        console.error('Error fetching translations for PaperPrints:', error);
      }
    };

    fetchTranslations();
  }, [language]);

  // Automatic slide transition
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) =>
        prevSlide === slides.length - 1 ? 0 : prevSlide + 1
      );
    }, 10000); // Change slide every 10 seconds

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [slides.length, currentSlide]);

  // Manual slide navigation
  const moveSlide = (direction) => {
    if (direction === 'next') {
      setCurrentSlide((prevSlide) =>
        prevSlide === slides.length - 1 ? 0 : prevSlide + 1
      );
    } else if (direction === 'prev') {
      setCurrentSlide((prevSlide) =>
        prevSlide === 0 ? slides.length - 1 : prevSlide - 1
      );
    }
  };

  // Handle email registration (you can connect this to an API)
  const handleEmailSubmit = async (e) => {
    e.preventDefault();

    if (!email) {
      setMessage('Please enter a valid email.');
      return;
    }

    try {
      // Simulate an API request to register the email (replace with actual API)
      const response = await fetch('/api/register-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (!response.ok) {
        throw new Error('Failed to register email');
      }

      setMessage('Email successfully registered!');
      setEmail(''); // Clear email input after submission
    } catch (error) {
      console.error('Error registering email:', error);
      setMessage('Failed to register email.');
    }
  };

  return (
    <div>
    <div className="slider-container">
      {slides.length > 0 && (
        <div className="slider" style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
          {slides.map((slide, index) => (
            <div className="slide" key={index}>
              <div className="slide-image-container">
                <img src={slide.img} alt={slide.title} className="slide-image" />
              </div>
              <div className="slide-text-container">
                <h2>{slide.title}</h2>
                <p>{slide.text}</p>
                {slide.input && (
                  <form onSubmit={handleEmailSubmit}>
                    <input
                      type="email"
                      placeholder="Enter your email"
                      className="email-input"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <button type="submit" className="submit-email-button">
                      Submit
                    </button>
                  </form>
                )}
                {message && <p className="email-message">{message}</p>}
                {slide.button && (
                  <button className="shop-now-button">{slide.button}</button>
                )}
              </div>
            </div>
          ))}
        </div>
      )}

      <button className="prev" onClick={() => moveSlide('prev')}>&#10094;</button>
      <button className="next" onClick={() => moveSlide('next')}>&#10095;</button>
    </div>
    <div className="slider-footer-text">
    Dessa tjänster levereras med hög upplösning och snabb service för att möta dina krav, 
    med format upp till A3 som säkerställer att varje detalj syns tydligt och skarpt.
  </div>
  </div>
  );
};

export default PaperPrints;

/*

1. **Poster**  
Vi erbjuder posters i hög kvalitet med klara färger och skarpa detaljer, tryckta på hållbart papper som framhäver varje motiv på bästa sätt. Med hög upplösning upp till A3-format levererar vi snabbt och pålitligt för att möta dina behov, oavsett om det gäller marknadsföring, evenemang eller dekoration.

2. **Flyers och vykort**  
Våra flyers och vykort trycks med omsorg på högkvalitativa papper för att säkerställa att ditt budskap står ut. Med hög upplösning upp till A3-format kan du lita på att dina trycksaker levereras snabbt, med skarpa tryck och levande färger som gör ett starkt intryck, perfekt för marknadsföringskampanjer och inbjudningar.

3. **Fine art papper: Scandia 2000, Conqueror Laid**  
För konsttryck eller exklusiva projekt erbjuder vi tryck på förstklassiga papper som Scandia 2000 och Conqueror Laid. Dessa papper ger en lyxig känsla och en exceptionell ytfinish som framhäver detaljerna i varje design, med upplösning upp till A3-format. Vi garanterar både högsta kvalitet och snabba leveranstider.

4. **Visitkort (Business card)**  
Vi skapar professionella visitkort med hög precision och kvalitet, tryckta på robusta papper med en exklusiv finish. Våra visitkort, tillgängliga i hög upplösning upp till A3-format för större material, är designade för att lämna ett bestående intryck och levereras snabbt för att matcha ditt hektiska schema.

5. **Studentskylt**  
Våra studentskyltar är av högsta kvalitet, tryckta med klarhet och hållbarhet för att stå ut under firandet. Vi erbjuder hög upplösning upp till A3-format för att säkerställa att detaljerna framhävs, och du får din studentskylt redo i tid för den stora dagen.

6. **Bild**  
Vi erbjuder högupplösta bildtryck som fångar varje detalj och färgnyans, med upplösning upp till A3-format för att göra varje motiv rättvisa. Våra bildtryck är perfekta för allt från personliga foton till konstverk, och vi levererar snabbt utan att kompromissa på kvaliteten.

Dessa tjänster levereras med hög upplösning och snabb service för att möta dina krav, med format upp till A3 som säkerställer att varje detalj syns tydligt och skarpt.*/

/*

1. **Poster**  
We offer high-quality posters with vivid colors and sharp details, printed on durable paper that enhances every design. With high resolution up to A3 size, we deliver quickly and reliably to meet your needs, whether for marketing, events, or decoration.

2. **Flyers and Postcards**  
Our flyers and postcards are carefully printed on high-quality paper to ensure that your message stands out. With high resolution up to A3 size, you can trust that your prints will be delivered quickly, with sharp prints and vibrant colors that leave a strong impression, perfect for marketing campaigns and invitations.

3. **Fine Art Paper: Scandia 2000, Conqueror Laid**  
For art prints or exclusive projects, we offer printing on premium papers like Scandia 2000 and Conqueror Laid. These papers provide a luxurious feel and exceptional finish, enhancing the details in every design, with resolution up to A3 size. We guarantee both top quality and fast delivery.

4. **Business Cards**  
We create professional business cards with high precision and quality, printed on sturdy paper with an exclusive finish. Our business cards, available in high resolution up to A3 size for larger materials, are designed to leave a lasting impression and are delivered quickly to match your busy schedule.

5. **Graduation Signs**  
Our graduation signs are of the highest quality, printed with clarity and durability to stand out during celebrations. We offer high resolution up to A3 size to ensure that the details shine through, and you’ll have your graduation sign ready in time for the big day.

6. **Image Prints**  
We provide high-resolution image prints that capture every detail and color nuance, with resolution up to A3 size to do justice to each design. Our image prints are perfect for anything from personal photos to artwork, and we deliver quickly without compromising on quality.

These services are delivered with high resolution and fast service to meet your requirements, with formats up to A3 ensuring every detail is clear and sharp. */