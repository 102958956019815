import React, { useState, useEffect } from 'react';
import RegisterUser from '../../api/RegisterUser'; // Import the RegisterUser component
import RegisterForm from './RegisterForm'; // Import the RegisterForm component
import './Register.css';

const Register = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [registerError, setRegisterError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [userTemplate, setUserTemplate] = useState(null); // State to hold the user template

  // Fetch the user template from public/data/userTemplate.json on component mount
  useEffect(() => {
    const fetchTemplate = async () => {
      try {
        const response = await fetch('/data/userTemplate.json');
        const template = await response.json();
        setUserTemplate(template);
      } catch (error) {
        console.error('Error fetching user template:', error);
      }
    };

    fetchTemplate();
  }, []);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setRegisterError('');
    setSuccessMessage('');

    // Passwords should match
    if (password !== confirmPassword) {
      setRegisterError('Passwords do not match.');
      return;
    }

    if (!userTemplate) {
      setRegisterError('Unable to load registration template. Please try again.');
      return;
    }

    // Populate the template with form data, using delivery details for billing as well
    const populatedUserData = {
      ...userTemplate,
      userName: username,
      emailAddress: email,
      password: password,
      repeatPassword: password,
      delivery: {
        firstName: firstName,
        lastName: lastName,
        phone: phoneNumber,
      },
      billing: {
        firstName: firstName, // Use the same first name as delivery
        lastName: lastName, // Use the same last name as delivery
        phone: phoneNumber, // Use the same phone number as delivery
      },
    };

    try {
      const response = await RegisterUser(populatedUserData); // Call the RegisterUser function

      if (!response.ok) {
        const errorData = await response.json();
        setRegisterError(errorData.message || 'Registration failed. Please try again.');
      } else {
        const data = await response.json();
        setSuccessMessage('Registration successful! You can now log in.');

        // Clear form fields after successful registration
        setUsername('');
        setEmail('');
        setPhoneNumber('');
        setFirstName('');
        setLastName('');
        setPassword('');
        setConfirmPassword('');

        // Note: If the server sends the token as a cookie, the browser handles it automatically,
        // and you won't need to do anything else to save it.
      }
    } catch (error) {
      setRegisterError(error.message);
    }
  };

  return (
    <RegisterForm
      username={username}
      setUsername={setUsername}
      email={email}
      setEmail={setEmail}
      phoneNumber={phoneNumber}
      setPhoneNumber={setPhoneNumber}
      firstName={firstName}
      setFirstName={setFirstName}
      lastName={lastName}
      setLastName={setLastName}
      password={password}
      setPassword={setPassword}
      confirmPassword={confirmPassword}
      setConfirmPassword={setConfirmPassword}
      showPassword={showPassword}
      setShowPassword={setShowPassword}
      showConfirmPassword={showConfirmPassword}
      setShowConfirmPassword={setShowConfirmPassword}
      handleSubmit={handleSubmit}
      registerError={registerError}
      successMessage={successMessage}
    />
  );
};

export default Register;