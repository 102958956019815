import React from 'react';

const LoginFormInputs = ({ username, password, setUsername, setPassword }) => {
  return (
    <div>
      <label htmlFor="username" className="login-label">Username:</label>
      <input
        type="text"
        id="username"
        className="login-input"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        required
      />
      
      <label htmlFor="password" className="login-label">Password:</label>
      <input
        type="password"
        id="password"
        className="login-input"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        required
      />
    </div>
  );
};

export default LoginFormInputs;