import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import LanguageSelector from '../LanguageSelector';
import Login from '../utils/login/Login';
import UserDropdown from '../utils/login/UserDropdown';
import ContentDisplay from '../utils/ContentDisplay';
import Cookies from 'js-cookie';
import './Header.css';
import ShowCart from '../cart/ShowCart';

const Header = ({ language, onChangeLanguage, selectedLanguage, cart, onDeleteItem }) => {
  console.log("Martin::Header");

  const [translations, setTranslations] = useState({});
  const [loggedIn, setLoggedIn] = useState(false); // Tracks login status
  const [username, setUsername] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTranslations = async () => {
      const url = `/languages/${language === 'sv' ? 'swedish' : 'english'}.json`;
      try {
        const response = await fetch(url);
        const data = await response.json();
        setTranslations(data);
      } catch (error) {
        console.error('Error fetching translations:', error);
      }
    };
    fetchTranslations();
  }, [language]);

  useEffect(() => {
    const storedUsername = Cookies.get('username');
    const storedLoggedIn = Cookies.get('loggedIn');
    if (storedLoggedIn === 'true') {
      setLoggedIn(true);
      console.log("Martin::Header::loggedIn" + loggedIn);
      setUsername(storedUsername);
    }
  }, []);

  const headerKey = 'Welcome to PrintlyQuick';
  const headerTranslation = translations[headerKey] || 'Translation not found';

  const handleLogin = (username) => {
    setLoggedIn(true); // Set login state to true
    setUsername(username);
    Cookies.set('username', username, { expires: 7 });
    Cookies.set('loggedIn', 'true', { expires: 7 });
  };

  const handleLogout = () => {
    setLoggedIn(false); // Set login state to false
    setUsername('');
    Cookies.remove('username');
    Cookies.remove('loggedIn');
    navigate('/');
  };

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.header-cart-link') && !event.target.closest('.header-cart-dropdown')) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const cartItemCount = cart.reduce((total, item) => total + item.quantity, 0);

  return (
    <header className="header-wrapper">
      <div className="header-content-wrapper">
        <nav>
          <Link to="/" className="header-home-link">
            <h1>{headerTranslation}</h1>
          </Link>
        </nav>

        <div className="header-centered-text">Personalisera din värld</div>

        <LanguageSelector
          onChangeLanguage={onChangeLanguage}
          selectedLanguage={selectedLanguage}
        />

        <div className="header-cart-link" onClick={toggleDropdown}>
          Kundvagn ({cartItemCount})
        </div>

        {loggedIn ? (
          <UserDropdown username={username} onLogout={handleLogout} />
        ) : (
          <Login onLogin={handleLogin} />
        )}
      </div>

      <div className={`header-cart-dropdown ${isDropdownOpen ? 'open' : ''}`}>
        <ShowCart cart={cart} onDeleteItem={onDeleteItem} />
      </div>

      <ContentDisplay />
    </header>
  );
};

export default Header;
