import axios from 'axios';

const apiService = {
  async post(action, params) {
    try {
      console.log("1---API Call Params:", params);
      let response = await axios.post(action, params);
      return response.data;
    } catch (error) {
      console.error("API error:", error);
      throw error;  
    }
  },

  async get(action) {
    try {
      console.log("2---API Call Action:", action);
      let response = await axios.get(action);
      return response.data;
    } catch (error) {
      console.error("API error:", error);
      throw error;
    }
  }
};

export default apiService;