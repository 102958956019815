import React, { useEffect, useState } from 'react';
import './css/TextilePrints.css';

const TextilePrints = ({ language }) => {
  const [slides, setSlides] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [email, setEmail] = useState(''); // Track the email input state
  const [message, setMessage] = useState(''); // Track success/error messages

  // Fetch translations based on the selected language
  useEffect(() => {
    const fetchTranslations = async () => {
      const url = `/languages/${language === 'sv' ? 'swedish' : 'english'}.json`;

      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`Failed to fetch translations: ${response.status}`);
        }

        const data = await response.json();
        // Define slides using truncated data
        setSlides([

          // Slide for Tote Bags
          {
            img: `/assets/images/tote_bag.jpg`,
            title: 'Anpassade Tryckta Tygkassar',
            text: 'Bär ditt varumärke överallt med våra högkvalitativa anpassade tygkassar. Perfekta för giveaways, reklam eller daglig användning.',
            button: 'Shoppa Nu',
          },
        
          // Slide for Fabric Labels
          {
            img: `/assets/images/fabric_label.jpg`,
            title: 'Personliga Tygetiketter',
            text: 'Ge dina textilprodukter en professionell touch med personliga tygetiketter, anpassade för ditt varumärke.',
            button: 'Läs Mer',
          },
        
          // Slide for Drawstring Backpacks
          {
            img: `/assets/images/drawstring_backpack.jpg`,
            title: 'Märkta Gymnastikpåsar med Dragsko',
            text: 'Lätta och hållbara, perfekta för gymmet, skolan eller evenemang. Anpassa dem med din egen design!',
            button: 'Shoppa Nu',
          },
        
          // Slide for Custom Scarves
          {
            img: `/assets/images/scarf.jpg`,
            title: 'Specialdesignade Halsdukar',
            text: 'Mode möter funktion med våra specialdesignade halsdukar. Perfekta som gåvor eller märkesvaror.',
            button: 'Utforska Nu',
          },
        
          // Slide for Fabric Keychains
          {
            img: `/assets/images/fabric_keychain.jpg`,
            title: 'Tygnyckelringar med Tryckta Designer',
            text: 'Ta ditt varumärke med dig med våra specialgjorda tygnyckelringar. Perfekta för reklam och evenemang.',
            button: 'Shoppa Nu',
          },
        
          // Slide for Cushion Covers
          {
            img: `/assets/images/cushion_cover.jpg`,
            title: 'Anpassade Kuddfodral',
            text: 'Förvandla vilket utrymme som helst med våra anpassade tryckta kuddfodral, tillgängliga i olika tyger.',
            button: 'Läs Mer',
          },
        
          // Slide for Custom Bandanas
          {
            img: `/assets/images/bandana.jpg`,
            title: 'Skräddarsydda Bandanas',
            text: 'Stick ut från mängden med våra skräddarsydda bandanas. Ett modeuttryck eller reklamartikel!',
            button: 'Utforska Nu',
          },
        
          // Slide for Printed Aprons
          {
            img: `/assets/images/apron.jpg`,
            title: 'Tryckta Förkläden för Alla Tillfällen',
            text: 'Våra specialtryckta förkläden är perfekta för kockar, konstnärer eller alla som vill skydda sina kläder.',
            button: 'Shoppa Nu',
          },
        
          // Slide for Face Masks
          {
            img: `/assets/images/face_mask.jpg`,
            title: 'Återanvändbara Tryckta Ansiktsmasker',
            text: 'Håll dig säker och stilig med våra återanvändbara tryckta ansiktsmasker. Anpassa dem med din egen design.',
            button: 'Läs Mer',
          },
        
          // Slide for Textile Wallets
          {
            img: `/assets/images/textile_wallet.jpg`,
            title: 'Specialdesignade Tygplånböcker',
            text: 'Våra specialtryckta tygplånböcker erbjuder både funktion och stil, perfekt för modeintresserade kunder.',
            button: 'Shoppa Nu',
          },
        
        ]);
      } catch (error) {
        console.error('Error fetching translations for TextilePrints:', error);
      }
    };

    fetchTranslations();
  }, [language]);

  // Automatic slide transition
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) =>
        prevSlide === slides.length - 1 ? 0 : prevSlide + 1
      );
    }, 10000); // Change slide every 10 seconds

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [slides.length]);

  // Manual slide navigation
  const moveSlide = (direction) => {
    if (direction === 'next') {
      setCurrentSlide((prevSlide) =>
        prevSlide === slides.length - 1 ? 0 : prevSlide + 1
      );
    } else if (direction === 'prev') {
      setCurrentSlide((prevSlide) =>
        prevSlide === 0 ? slides.length - 1 : prevSlide - 1
      );
    }
  };

  // Handle email registration (you can connect this to an API)
  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    if (!email) {
      setMessage('Please enter a valid email.');
      return;
    }

    try {
      // Simulate an API request to register the email (you'll replace this with actual API)
      const response = await fetch('/api/register-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (!response.ok) {
        throw new Error('Failed to register email');
      }

      setMessage('Email successfully registered!');
      setEmail(''); // Clear email input after submission
    } catch (error) {
      console.error('Error registering email:', error);
      setMessage('Failed to register email.');
    }
  };

  return (
    <div className="TextilePrints-container">
      <div className="TextilePrints" style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
        {slides.map((slide, index) => (
          <div className="slide" key={index}>
            <div className="slide-image-container">
              <img src={slide.img} alt={slide.title} className="slide-image" />
            </div>
            <div className="slide-text-container">
              <h2>{slide.title}</h2>
              <p>{slide.text}</p>
              {slide.input && (
                <form onSubmit={handleEmailSubmit}>
                  <input
                    type="email"
                    placeholder="Enter your email"
                    className="email-input"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <button type="submit" className="submit-email-button">
                    Submit
                  </button>
                </form>
              )}
              {message && <p className="email-message">{message}</p>}
              {slide.button && (
                <button className="shop-now-button">{slide.button}</button>
              )}
            </div>
          </div>
        ))}
      </div>
      <button className="prev" onClick={() => moveSlide('prev')}>
        &#10094;
      </button>
      <button className="next" onClick={() => moveSlide('next')}>
        &#10095;
      </button>
    </div>
  );
};

export default TextilePrints;

/*

### 1. **Title: Custom Printed Tote Bags**
   **Description**:  
   "Carry your brand everywhere with our high-quality custom tote bags. Perfect for giveaways, promotions, or everyday use, these eco-friendly bags can be printed with any design, making them an excellent accessory for textile printing."

### 2. **Title: Personalized Fabric Labels**
   **Description**:  
   "Add a professional touch to your textile products with personalized fabric labels. Whether you need custom tags for clothing or branded labels for bags, our fabric labels ensure your brand stands out with every stitch."

### 3. **Title: Branded Drawstring Backpacks**
   **Description**:  
   "Our branded drawstring backpacks are lightweight, durable, and fully customizable with your printed designs. Ideal for gyms, schools, or events, these bags make a stylish and functional accessory to promote your brand."

### 4. **Title: Custom Printed Scarves**
   **Description**:  
   "Fashion meets function with our custom printed scarves. Perfect as gifts or branded merchandise, these high-quality scarves allow you to showcase your designs in vivid color and soft, luxurious fabrics."

### 5. **Title: Fabric Keychains with Printed Designs**
   **Description**:  
   "Take your brand on the go with custom fabric keychains. Printed with your logo or unique designs, these small but impactful accessories are perfect for promotions, events, or as part of your merchandise collection."

### 6. **Title: Personalized Cushion Covers**
   **Description**:  
   "Turn any space into a branding opportunity with our custom printed cushion covers. Available in a variety of fabrics, these cushion covers offer a soft, comfortable way to display your designs in homes or offices."

### 7. **Title: Customized Bandanas**
   **Description**:  
   "Stand out from the crowd with our custom printed bandanas. Whether worn as a fashion statement or given away as promotional items, these versatile accessories are perfect for showcasing your unique textile prints."

### 8. **Title: Printed Aprons for All Occasions**
   **Description**:  
   "Our custom printed aprons are perfect for chefs, artists, or anyone looking for a stylish way to protect their clothing. Available in various styles, these aprons can be customized with any design to represent your brand or personality."

### 9. **Title: Reusable Printed Face Masks**
   **Description**:  
   "Stay safe and stylish with our reusable printed face masks. Available in a range of fabrics, these masks provide comfort and customization, allowing you to promote your designs while staying protected."

### 10. **Title: Custom Textile Wallets**
   **Description**:  
   "Our custom printed textile wallets offer both functionality and style. With ample space for cards and cash, these wallets can be printed with your designs, making them the perfect accessory for fashion-forward customers."

These titles and descriptions are designed to highlight the versatility and appeal of textile printing accessories while being engaging for customers across different markets. Let me know if you'd like more suggestions or any specific types of accessories!*/
/*Here’s the Swedish translation for the titles and descriptions:

### 1. **Titel: Anpassade Tryckta Tygkassar**
   **Beskrivning**:  
   "Bär ditt varumärke överallt med våra högkvalitativa anpassade tygkassar. Perfekta för giveaways, reklam eller daglig användning. Dessa miljövänliga kassar kan tryckas med vilken design som helst och är ett utmärkt tillbehör för textiltryck."

### 2. **Titel: Personliga Tygetiketter**
   **Beskrivning**:  
   "Ge dina textilprodukter en professionell touch med personliga tygetiketter. Oavsett om du behöver anpassade etiketter för kläder eller märkesetiketter för väskor, ser våra tygetiketter till att ditt varumärke syns vid varje söm."

### 3. **Titel: Märkta Gymnastikpåsar med Dragsko**
   **Beskrivning**:  
   "Våra gymnastikpåsar med dragsko är lätta, hållbara och helt anpassningsbara med dina tryckta designer. Perfekta för gymmet, skolan eller evenemang, dessa väskor är både stiliga och funktionella för att marknadsföra ditt varumärke."

### 4. **Titel: Specialdesignade Halsdukar**
   **Beskrivning**:  
   "Mode möter funktion med våra specialdesignade halsdukar. Perfekta som gåvor eller märkesvaror, dessa högkvalitativa halsdukar låter dig visa upp dina designer i livfulla färger och mjuka, lyxiga tyger."

### 5. **Titel: Tygnyckelringar med Tryckta Designer**
   **Beskrivning**:  
   "Ta ditt varumärke med dig med våra specialgjorda tygnyckelringar. Tryckta med din logotyp eller unika designer, dessa små men kraftfulla tillbehör är perfekta för reklam, evenemang eller som en del av din varumärkeskollektion."

### 6. **Titel: Anpassade Kuddfodral**
   **Beskrivning**:  
   "Förvandla vilket utrymme som helst till en brandingmöjlighet med våra anpassade tryckta kuddfodral. Finns i olika tyger och ger ett mjukt, bekvämt sätt att visa dina designer i hem eller kontor."

### 7. **Titel: Skräddarsydda Bandanas**
   **Beskrivning**:  
   "Stick ut från mängden med våra skräddarsydda bandanas. Oavsett om de används som ett modeuttryck eller ges bort som reklammaterial, är dessa mångsidiga tillbehör perfekta för att visa upp dina unika textiltryck."

### 8. **Titel: Tryckta Förkläden för Alla Tillfällen**
   **Beskrivning**:  
   "Våra specialtryckta förkläden är perfekta för kockar, konstnärer eller alla som letar efter ett snyggt sätt att skydda sina kläder. Finns i olika stilar och kan anpassas med vilken design som helst för att representera ditt varumärke eller personlighet."

### 9. **Titel: Återanvändbara Tryckta Ansikts */