import React from 'react';
import { globalVariable } from '../../../utils/globals';

const DeleteItemAPI = () => {
    const apiUrl = `${globalVariable.ApiBaseUri}${process.env.REACT_APP_VEBSERVICE_DELETE_ITEM}`;

    const handleDeleteItem = async (item) => {
        console.log('DeleteItemAPI.js..Deleting item from cart:', item);
        const newApiUrl = `${apiUrl}${item.code}/product/1`;
        console.log('DeleteItemAPI.js..newApiUrl:', newApiUrl);

        try {
            const response = await fetch(newApiUrl, {
                mode: 'cors',
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(item), 
            });

            if (!response.ok && response.status !== 204) {
                throw new Error('Failed to delete item from cart ' + response.status);
            }

            // Handle response based on status
            if (response.status === 204) {
                console.log('DeleteItemAPI.js..Item deleted successfully, no content returned.');
                return { success: true, item };
            }

            const data = await response.json();
            console.log('DeleteItemAPI.js..data:', data);
            return { success: true, item };

        } catch (error) {
            console.error('Error deleting item from cart: ', item, " ", error);
            return { success: false, error: error.message };
        }
    };

    return { handleDeleteItem }; 
};

export default DeleteItemAPI;