import React, { useEffect, useState } from 'react';
import './css/Accessories.css'; 
const Accessories = () => {
    const [slides, setSlides] = useState([]);
    const [currentSlide, setCurrentSlide] = useState(0);

    useEffect(() => {
        setSlides([
            {
                img: `/assets/images/coverMobile.jpg`,
                title: "Mobile Phone Cover",
                text: "Skydda din telefon med stil med våra premiumfodral. Tillverkade av högkvalitativa material erbjuder de både elegans och skydd mot stötar och repor. Välj bland olika färger och mönster för att hitta ditt perfekta fodral!**",
                button: "Learn More",
            },
            // Slide for Mug
            {
                img: `/assets/images/mug.jpg`,
                title: 'Mugg',
                text: 'Vi erbjuder muggar av hög kvalitet som kan tryckas med din bild eller design, perfekt för företagsgåvor eller personlig användning.',
                button: 'Learn More',
            },
            // Slide for Pen
            {
                img: `/assets/images/pen.jpg`,
                title: 'Penna',
                text: 'Våra pennor är både eleganta och funktionella, perfekta för kontoret eller som en gåva. Anpassa dem med ditt varumärke.',
                button: 'Learn More',
            },
            // Slide for Pencil
            {
                img: `/assets/images/pencil.jpg`,
                title: 'Blyertspenna',
                text: 'Vi erbjuder blyertspennor av hög kvalitet som kan anpassas för att passa ditt varumärke. Idealisk för skolor och kontor.',
                button: 'Learn More',
            },
            // Slide for Bag
            {
                img: `/assets/images/bag.jpg`,
                title: 'Väska',
                text: 'Våra väskor är både praktiska och stiliga. Perfekta för marknadsföring eller som gåvor vid evenemang.',
                button: 'Learn More',
            },
            // Slide for Water Bottle
            {
                img: `/assets/images/waterbottle.jpg`,
                title: 'Vattenflaska',
                text: 'Håll dig hydrerad med våra miljövänliga vattenflaskor. Vi kan trycka din bild på flaskan, vilket gör den perfekt för gymmet eller på språng.',
                button: 'Learn More',
            },
        ]);
    }, []);

    // Automatic slide transition
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentSlide((prevSlide) =>
                prevSlide === slides.length - 1 ? 0 : prevSlide + 1
            );
        }, 10000); // Change slide every 10 seconds

        return () => clearInterval(interval); // Cleanup interval on unmount
    }, [slides.length]);

    // Manual slide navigation
    const moveSlide = (direction) => {
        if (direction === 'next') {
            setCurrentSlide((prevSlide) =>
                prevSlide === slides.length - 1 ? 0 : prevSlide + 1
            );
        } else if (direction === 'prev') {
            setCurrentSlide((prevSlide) =>
                prevSlide === 0 ? slides.length - 1 : prevSlide - 1
            );
        }
    };

    return (
        <div className="accessories-container">
            <div className="accessories" style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
                {slides.map((slide, index) => (
                    <div className="slide" key={index}>
                        <div className="slide-image-container">
                            <img src={slide.img} alt={slide.title} className="slide-image" />
                        </div>
                        <div className="slide-text-container">
                            <h2>{slide.title}</h2>
                            <p>{slide.text}</p>
                            {slide.button && (
                                <button className="shop-now-button">{slide.button}</button>
                            )}
                        </div>
                    </div>
                ))}
            </div>

            <button className="prev" onClick={() => moveSlide('prev')}>
                &#10094;
            </button>
            <button className="next" onClick={() => moveSlide('next')}>
                &#10095;
            </button>

            {/* Additional text under the slider */}
            <div className="slider-footer-text">
                Dessa tjänster levereras med hög upplösning och snabb service för att möta dina krav, 
                med format upp till A3 som säkerställer att varje detalj syns tydligt och skarpt.
            </div>
        </div>
    );
};

export default Accessories;