import React, { useEffect, useState } from 'react';
import AddItem from './AddItem';
import DropdownCart from './DropDownCart';

const Cart = () => {
  const initialCart = [
    { id: 1, name: 'Product 1', price: 29.99, quantity: 1 },
    { id: 2, name: 'Product 2', price: 19.99, quantity: 2 },
    { id: 3, name: 'Product 3', price: 39.99, quantity: 1 },
  ];

  const [cart, setCart] = useState(initialCart);

  // Handle adding a new item to the cart
  const handleAddItem = (newItem) => {

    console.log("Martin::handleAddItem")
    console.log(newItem)
    setCart([...cart, newItem]);
  };

  // Listen for the custom 'addItem' event from the test component
  useEffect(() => {
    const handleAddItemEvent = (e) => {
        console.log("Martin::handleAddItemEvent")
      handleAddItem(e.detail); // e.detail contains the new product item
    };

    window.addEventListener('addItem', handleAddItemEvent);

    return () => {
      window.removeEventListener('addItem', handleAddItemEvent);
    };
  }, [cart]);

  // Handle reducing the quantity of an item
  const handleReduceItem = (id) => {
    setCart(
      cart.map((item) =>
        item.id === id && item.quantity > 1
          ? { ...item, quantity: item.quantity - 1 }
          : item
      )
    );
  };

  // Handle deleting an item
  const handleDeleteItem = (id) => {
    setCart(cart.filter((item) => item.id !== id));
  };

  return (
    <div>
      {/* Add Item Form */}
      <AddItem onAddItem={handleAddItem} />

      {/* Dropdown Cart */}
      <DropdownCart 
        cart={cart} 
        onDeleteItem={handleDeleteItem} 
        onReduceItem={handleReduceItem} 
      />
    </div>
  );
};

export default Cart;