import React from 'react';
import { CardElement } from '@stripe/react-stripe-js';

const CustomCardNumberElement = () => {
  return (
    <div className="form-group">
      <label htmlFor="card-element">Enter credit or debit card below</label>
      <CardElement options={{ style: { base: { fontSize: '16px' } } }} />
    </div>
  );
};

export default CustomCardNumberElement;