import React from 'react';
import { globalVariable } from '../../../utils/globals';

const ReduceItemAPI = () => {
  const apiUrl = globalVariable.ApiBaseUri+process.env.REACT_APP_VEBSERVICE_REDUCE_ITEM;

  const handleReduceItem = async (item) => {
    console.log('ReduceItemAPI.js..Reducing item quantity:', item);
    const NapiUrl=apiUrl+item.code;
    try {
      const response = await fetch(NapiUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify( item ),
      });

      if (!response.ok) {
        throw new Error('Failed to reduce item quantity');
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error reducing item quantity:', error);
      throw error;
    }
  };

  return { handleReduceItem };
};

export default ReduceItemAPI;