// SettingsPanel.js

import React from 'react';
import BackgroundSelector from '../BackgroundSelector';
import LogoUpload from '../LogoUpload';

const SettingsPanel = ({
  setBackgroundImage, setLogoImage, fontColor, setFontColor,
  fontFamily, setFontFamily, handleBackgroundUpload
}) => {
  return (
    <div className="visa-setting">
      {/* Background Selector */}
      <BackgroundSelector setBackgroundImage={setBackgroundImage} />

      {/* Logo Upload */}
      <LogoUpload setLogoImage={setLogoImage} />

      {/* Background Upload Input */}
      <div className="visa-background-upload">
        <label htmlFor="background-upload">Upload Background:</label>
        <input type="file" id="background-upload" accept="image/*" onChange={handleBackgroundUpload} />
      </div>

      {/* Font Color Picker */}
      <div className="visa-color-picker">
        <label>Font Color:</label>
        <select onChange={(e) => setFontColor(e.target.value)} value={fontColor}>
          <option value="black">Black</option>
          <option value="white">White</option>
          <option value="red">Red</option>
          <option value="yellow">Yellow</option>
          <option value="green">Green</option>
        </select>
      </div>

      {/* Font Family Picker */}
      <div className="visa-font-picker">
        <label>Font Family:</label>
        <select onChange={(e) => setFontFamily(e.target.value)} value={fontFamily}>
          <option value="Roboto">Roboto</option>
          <option value="Arial">Arial</option>
          <option value="Times New Roman">Times New Roman</option>
          <option value="Courier New">Courier New</option>
          <option value="Georgia">Georgia</option>
          <option value="Verdana">Verdana</option>
        </select>
      </div>
    </div>
  );
};

export default SettingsPanel;
