import React, { useEffect, useState } from 'react';
import './css/Slider.css';
import RegisterNewsLetterEmail from '../api/RegisterNewsLetterEmail'; 

const Slider = ({ language, onAddToCart }) => {
  const [slides, setSlides] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [cartMessage, setCartMessage] = useState('');

  useEffect(() => {
    const fetchTranslations = async () => {
      const url = `/languages/${language === 'sv' ? 'swedish' : 'english'}.json`;
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`Failed to fetch translations: ${response.status}`);
        }
        const data = await response.json();
        setSlides([
          {
            img: `/assets/images/A2gWwFnX_1726685982486_raw.jpg`,
            title: data.HeadLine,
            text: data.HeadLineText_1,
            input: true,
            itemDetails: { id:1, product: "SKU1234560", name: 'Item 1', price: 10 },
          },
          {
            img: `/assets/images/ImYU0iCm_1726779142813_raw.jpg`,
            title: data.HeadLineText_2,
            text: data.HeadLineText_3,
            button: 'Shop Now',
            itemDetails: { id: "SKU123456", name: 'Item 2', price: 20 },
          },
          {
            img: `/assets/images/TacJauv4_1726686303178_raw.jpg`,
            title: data.HeadLineText_4,
            text: data.HeadLineText_5,
            button: 'Discover Now',
            itemDetails: { id:3, product: "SKU1234562", name: 'Item 3', price: 30 },
          },
          {
            img: `/assets/images/FkCIY-zA_1726686389271_raw.jpg`,
            title: data.HeadLineText_6,
            text: data.HeadLineText_7,
            button: 'Explore Now',
            itemDetails: { id:4, product: "SKU1234563", name: 'Item 4', price: 40 },
          },
        ]);
      } catch (error) {
        console.error('Error fetching translations for slider:', error);
      }
    };

    fetchTranslations();
  }, [language]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) =>
        prevSlide === slides.length - 1 ? 0 : prevSlide + 1
      );
    }, 10000);

    return () => clearInterval(interval);
  }, [slides.length]);

  const moveSlide = (direction) => {
    if (direction === 'next') {
      setCurrentSlide((prevSlide) =>
        prevSlide === slides.length - 1 ? 0 : prevSlide + 1
      );
    } else if (direction === 'prev') {
      setCurrentSlide((prevSlide) =>
        prevSlide === 0 ? slides.length - 1 : prevSlide - 1
      );
    }
  };

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    if (!email) {
      setMessage('Please enter a valid email.');
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setMessage('Please enter a valid email format.');
      return;
    }

    try {
      const response = await RegisterNewsLetterEmail({ email });
      if (!response.ok) {
        throw new Error('Failed to register email');
      }
      setMessage('Email successfully registered!');
      setEmail('');
    } catch (error) {
      console.error('Error registering email:', error);
      setMessage('Failed to register email.');
    }
  };

  const handleAddToCartClick = (itemDetails) => {
    if (itemDetails) {
      console.log('Martin::Adding to cart:', itemDetails);
      onAddToCart(itemDetails);
      setCartMessage(`${itemDetails.name} has been added to your cart.`);
      setTimeout(() => setCartMessage(''), 3000);
    }
  };

  return (
    <div className="slider-container">
      <div className="slider" style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
        {slides.map((slide, index) => (
          <div className="slide" key={index}>
            <div className="slide-image-container">
              <img src={slide.img} alt={slide.title} className="slide-image" />
            </div>
            <div className="slide-text-container">
              <h2>{slide.title}</h2>
              <p>{slide.text}</p>
              {slide.input && (
                <form onSubmit={handleEmailSubmit}>
                  <input
                    type="email"
                    placeholder="Enter your email"
                    className="email-input"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    aria-label="Email input"
                  />
                  <button type="submit" className="submit-email-button">
                    Submit
                  </button>
                </form>
              )}
              {message && <p className="email-message">{message}</p>}
              {cartMessage && <p className="cart-message">{cartMessage}</p>}
              {slide.button && (
                <button
                  className="shop-now-button"
                  onClick={() => handleAddToCartClick(slide.itemDetails)}
                  aria-label={`Add ${slide.title} to cart`}
                >
                  {slide.button}
                </button>
              )}
            </div>
          </div>
        ))}
      </div>
      <button className="prev" onClick={() => moveSlide('prev')} aria-label="Previous slide">
        &#10094;
      </button>
      <button className="next" onClick={() => moveSlide('next')} aria-label="Next slide">
        &#10095;
      </button>
    </div>
  );
};

export default Slider;