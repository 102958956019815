import React, { useEffect, useState } from 'react';
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import './css/Main.css';
import ProductSection from './products/ProductsSection';
import Register from './utils/register/Register';
import PaperPrints from './containspages/PaperPrints';
import AccessoriesPrints from './containspages/AccessoriesPrints';
import TextilePrints from './containspages/TextilePrints';
import WebEcommerceSolutions from './containspages/WebEcommerceSolutions';
import Cart from './cart/Cart';
import CheckOut from './checkout/CheckOut';
import CylindricalImageCanvasV1 from './designPage/curved/CylindricalImageCanvasV1';
import VisitingCardDesign from './designPage/VisitCard/VisitCardDesign';
import IphoneCoverDesign from  './designPage/covers/iphone15/IphoneCoverDesign';
import App from '../App.js';  // Make sure to import Home

const formatKeyToFilename = (key) => key.replace(/\s+/g, '-').toLowerCase();

const Main = ({ language, onAddToCart, cart, onDeleteItem, onReduceQuantity }) => {
  const [translations, setTranslations] = useState({});
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [productData, setProductData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTranslations = async () => {
      const url = `/languages/${language === 'en' ? 'english' : 'swedish'}.json`;
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`Failed to fetch translations: ${response.status}`);
        }
        const data = await response.json();
        setTranslations(data);
      } catch (error) {
        console.error("Error fetching translations:", error);
      }
    };

    fetchTranslations();
  }, [language]);

  const handleProductClick = (key) => {
    const formattedKey = formatKeyToFilename(key);
    setSelectedProduct(formattedKey);
    navigate(`/product/${formattedKey}`);
  };

  const handleProductData = (data) => {
    setProductData(JSON.parse(data));
  };

  const items = Object.keys(translations).slice(12);

  return (
    <div>
      <Routes>
        {/* Home route showing the ProductSection */}
        <Route
          path="/"
          element={
            <ProductSection
              translations={translations}
              selectedProduct={null/*selectedProduct*/}
              productData={null/*productData*/}
              handleProductData={handleProductData}
              setSelectedProduct={null/*setSelectedProduct*/}
              language={language}
              items={items}
              handleProductClick={handleProductClick}
              onAddToCart={onAddToCart}
            />
          }
        />

        {/* Product Detail Route */}
        <Route
          path="/product/:productId"
          element={
            <ProductSection
              translations={translations}
              selectedProduct={selectedProduct}
              productData={productData}
              handleProductData={handleProductData}
              setSelectedProduct={setSelectedProduct}
              language={language}
              items={items}
              handleProductClick={handleProductClick}
              onAddToCart={onAddToCart}
            />
          }
        />

        {/* Add route for Register */}
        <Route path="/register" element={<Register />} />

        <Route path="/containspages/Paper-prints" element={<PaperPrints />} />
        <Route path="/containspages/Accessories-prints" element={<AccessoriesPrints />} />
        <Route path="/containspages/Textile-prints" element={<TextilePrints />} />
        <Route path="/containspages/web-ecommerce-solutions" element={<WebEcommerceSolutions />} />
        <Route path="/product/mugs" element={<CylindricalImageCanvasV1 />} />
        <Route path="product/mobiecover" element={<IphoneCoverDesign />} />
        <Route path="/product/businesscards" element={<VisitingCardDesign />} />





        {/* Default route for undefined paths */}
        <Route path="*" element={<Navigate to="/" />} />

        {/* Define the home route */}
        <Route path="/" element={<App />} />  {/* You need to have this route defined after importing Home */}

        {/* Define the routes */}
        <Route path="/cart" element={<Cart />} />

        <Route
          path="/checkout"
          element={
            <CheckOut
              cart={cart}
              onReduceQuantity={onReduceQuantity}
              onRemoveItem={onDeleteItem}
            />
          }
        />
      </Routes>
    </div>
  );
};

export default Main;
