import React from 'react';
import { useNavigate } from 'react-router-dom';
import './css/ShowCart.css';

const ShowCart = ({ cart, onDeleteItem }) => {
  const navigate = useNavigate();
//console.log("Martin::showCart");
  const handleCheckout = () => {
    navigate('/checkout'); // Navigate to the checkout page
  };

  return (
    <div className="cart-dropdown-container">
      <ul>
        {cart.length === 0 ? (
          <li className="empty-cart-message">
            <p>Your cart is empty</p>
            <button onClick={() => navigate('/shop')}>Start Shopping</button>
          </li>
        ) : (
          cart.map((item) => (
            <li key={item.id} className="cart-item">
              {item.image && (
                <img src={item.image} alt={item.name} className="cart-item-image" />
              )}
              <span className="cart-item-details">
                {item.name} - <span className="quantity-label">Quantity:</span> {item.quantity}
              </span>
              <button
                className="remove-item-button"
                onClick={() => onDeleteItem(item.id)}
                aria-label={`Remove ${item.name} from cart`}
              >
                Remove
              </button>
            </li>
          ))
        )}
      </ul>

      {cart.length > 0 && (
        <div className="checkout-button-container">
          <button
            className="checkout-button"
            onClick={handleCheckout}
            aria-label="Proceed to checkout"
          >
            Checkout
          </button>
        </div>
      )}
    </div>
  );
};

export default ShowCart;