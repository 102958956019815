import React, { useState } from 'react';
import './css/CheckOut.css';
import Cookie from 'js-cookie';
import PersonalInfo from './PersonalInfo';

const CheckOut = ({ cart = [], onReduceQuantity, onRemoveItem }) => {
  const [showPersonalInfo, setShowPersonalInfo] = useState(false);
  const [selectedCode, setSelectedCode] = useState(null); // Track the selected code

  const isUserLoggedIn = Cookie.get('loggedIn'); // Check if user is logged in
  console.log("Martin::checkout.. " + isUserLoggedIn);

  // Handle the proceed click and extract the code for the selected item
  const handleProceedClick = (itemId) => {
    // Find the selected item by its id
    const selectedItem = cart.find(item => item.id === itemId);
    
    if (selectedItem) {
      setSelectedCode(selectedItem.code); // Set the code for the selected item
      setShowPersonalInfo(true); // Show the PersonalInfo component
    }
  };

  return (
    <div className="checkout-container">
      <h2>Checkout Page</h2>
      <p>Please review your order and proceed with payment.</p>

      {cart.length === 0 ? (
        <p>Your cart is empty</p>
      ) : (
        <div className="checkout-items">
          <ul>
            {cart.map((item) => (
              <li key={item.id} className="checkout-item">
                {item.image && (
                  <img
                    src={item.image}
                    alt={item.name}
                    className="checkout-item-image"
                  />
                )}
                <span>{item.name}</span>
                <span>Quantity: {item.quantity}</span>
                {item.price && <span>Price: ${item.price.toFixed(2)}</span>}

                <button
                  onClick={() => onReduceQuantity(item.id)}
                  className="reduce-quantity-button"
                >
                  Reduce Quantity
                </button>

                <button
                  onClick={() => onRemoveItem(item.id)}
                  className="remove-item-button"
                >
                  Remove
                </button>

                {/* Proceed button for each item */}
                <button
                  className="proceed-button"
                  onClick={() => handleProceedClick(item.id)} // Pass item ID on click
                >
                  Proceed to Payment
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* Conditional rendering of the PersonalInfo component */}
      {showPersonalInfo && (
        <div className="personal-info-div">
          {console.log("checkout::checkout.. " + selectedCode)}
          <PersonalInfo isUserLoggedIn={isUserLoggedIn} code={selectedCode} />
        </div>
      )}
    </div>
  );
};

export default CheckOut;