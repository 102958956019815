import React from 'react';
import CustomCardNumberElement from './CustomCardNumberElement';
import './css/PaymentForm.css';

const PaymentForm = ({ email, setEmail, handleSubmit, isProcessing, stripe, elements, code }) => { // Accept code as a prop

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <label htmlFor="email">Email Address</label>
        <input
          type="email"
          id="email"
          className="form-control"
          placeholder="Enter email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </div>

      <div className="form-group">
        <CustomCardNumberElement />
      </div>

      {/* Optional: You can log the code or use it in some way */}
      <input type="hidden" name="code" value={code} /> {/* If you need to send it in the form submission */}

      <button type="submit" className="btn btn-primary btn-block" disabled={!stripe || !elements || isProcessing}>
        {isProcessing ? (
          <>
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Processing...
          </>
        ) : (
          'Pay With Your Card'
        )}
      </button>
    </form>
  );
};

export default PaymentForm;