import React, { useState, useRef } from 'react';
import './IphoneCoverDesign.css';
import html2canvas from 'html2canvas';

const IphoneCoverDesign = () => {
  const productImage = '/assets/images/system/iphone-15-.jpg';

  const [uploadedImage1, setUploadedImage1] = useState(null);
  const [uploadedImage2, setUploadedImage2] = useState(null);
  const [uploadedImage3, setUploadedImage3] = useState(null); // New state for third image
  const [position1, setPosition1] = useState({ x: 0, y: 0 });
  const [position2, setPosition2] = useState({ x: 0, y: 0 });
  const [position3, setPosition3] = useState({ x: 0, y: 0 }); // New position state for third image
  const [opacity1, setOpacity1] = useState(0.7);
  const [opacity2, setOpacity2] = useState(0.7);
  const [opacity3, setOpacity3] = useState(0.7); // New opacity state for third image

  const coverRef = useRef(null);

  const handleImageUpload1 = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => setUploadedImage1(reader.result);
      reader.readAsDataURL(file);
    }
  };

  const handleImageUpload2 = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => setUploadedImage2(reader.result);
      reader.readAsDataURL(file);
    }
  };

  const handleImageUpload3 = (event) => { // New handler for third image
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => setUploadedImage3(reader.result);
      reader.readAsDataURL(file);
    }
  };

  const downloadImage = () => {
    html2canvas(coverRef.current).then((canvas) => {
      const link = document.createElement('a');
      link.href = canvas.toDataURL();
      link.download = 'iphone_cover.jpg';
      link.click();
    });
  };

  const handleKeyDown = (event) => {
    const step = 10;
    switch (event.key) {
      case 'ArrowUp':
        setPosition1((prev) => ({ ...prev, y: prev.y - step }));
        break;
      case 'ArrowDown':
        setPosition1((prev) => ({ ...prev, y: prev.y + step }));
        break;
      case 'ArrowLeft':
        setPosition1((prev) => ({ ...prev, x: prev.x - step }));
        break;
      case 'ArrowRight':
        setPosition1((prev) => ({ ...prev, x: prev.x + step }));
        break;
      default:
        break;
    }
  };

  const handleMouseDown = (e, imageNum) => {
    e.preventDefault();
    const startX = e.clientX - (imageNum === 1 ? position1.x : imageNum === 2 ? position2.x : position3.x);
    const startY = e.clientY - (imageNum === 1 ? position1.y : imageNum === 2 ? position2.y : position3.y);

    const handleMouseMove = (e) => {
      if (imageNum === 1) {
        setPosition1({ x: e.clientX - startX, y: e.clientY - startY });
      } else if (imageNum === 2) {
        setPosition2({ x: e.clientX - startX, y: e.clientY - startY });
      } else {
        setPosition3({ x: e.clientX - startX, y: e.clientY - startY });
      }
    };

    const handleMouseUp = () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const toggleOpacity = (imageNum) => {
    if (imageNum === 1) {
      setOpacity1((prevOpacity) => (prevOpacity === 0.7 ? 1 : 0.7));
    } else if (imageNum === 2) {
      setOpacity2((prevOpacity) => (prevOpacity === 0.7 ? 1 : 0.7));
    } else {
      setOpacity3((prevOpacity) => (prevOpacity === 0.7 ? 1 : 0.7)); // Toggle opacity for third image
    }
  };

  return (
    <div className="iphone-cover-app" onKeyDown={handleKeyDown} tabIndex="0">
      <h1 className="iphone-cover-heading">iPhone Cover Design</h1>

      <div className="iphone-cover-main">
        <div className="working-area" ref={coverRef}>
          <div
            className="background-layer"
            style={{
              backgroundImage: `url(${productImage})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          ></div>

          {uploadedImage1 && (
            <img
              src={uploadedImage1}
              className="overlay-layer"
              style={{
                top: `${position1.y}px`,
                left: `${position1.x}px`,
                opacity: opacity1,
                position: 'absolute',
                cursor: 'move',
              }}
              onMouseDown={(e) => handleMouseDown(e, 1)}
              alt="Uploaded overlay 1"
            />
          )}

          {uploadedImage2 && (
            <img
              src={uploadedImage2}
              className="overlay-layer"
              style={{
                top: `${position2.y}px`,
                left: `${position2.x}px`,
                opacity: opacity2,
                position: 'absolute',
                cursor: 'move',
              }}
              onMouseDown={(e) => handleMouseDown(e, 2)}
              alt="Uploaded overlay 2"
            />
          )}

          {uploadedImage3 && ( // Display third uploaded image
            <img
              src={uploadedImage3}
              className="overlay-layer"
              style={{
                top: `${position3.y}px`,
                left: `${position3.x}px`,
                opacity: opacity3,
                position: 'absolute',
                cursor: 'move',
              }}
              onMouseDown={(e) => handleMouseDown(e, 3)}
              alt="Uploaded overlay 3"
            />
          )}
        </div>

        <div className="camera-hole"></div>

        <div className="iphone-cover-settings">
          <input type="file" onChange={handleImageUpload1} />
          <input type="file" onChange={handleImageUpload2} />
          <input type="file" onChange={handleImageUpload3} /> {/* New input for third image */}
          <button className="iphone-cover-btn iphone-cover-download-btn" onClick={downloadImage}>
            Download Design
          </button>
          <button className="iphone-cover-btn" onClick={() => toggleOpacity(1)}>
            Toggle Opacity 1
          </button>
          <button className="iphone-cover-btn" onClick={() => toggleOpacity(2)}>
            Toggle Opacity 2
          </button>
          <button className="iphone-cover-btn" onClick={() => toggleOpacity(3)}> {/* New button for toggling opacity of third image */}
            Toggle Opacity 3
          </button>
        </div>
      </div>
    </div>
  );
};

export default IphoneCoverDesign;
