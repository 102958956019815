import { globalVariable } from "../../utils/globals";
import { getTokenFromCookie } from "../utils/cookieUtils"; // Import the token retrieval function

const processPayment = async (body, itemId) => {
  const token = getTokenFromCookie('token'); // Pass the actual token name as an argument

  const requestBody = {
    ...body,
    itemId,
  };

  const apiUrl =
    globalVariable.ApiBaseUri +
    process.env.REACT_APP_WEBSERVICE_POST_CHECKOUT.replace('__CODE__', itemId);
console.log('processPayment::API toke:', token);
  try {
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token ? `Bearer ${token}` : '', // Add token to the Authorization header if it exists
      },
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
      throw new Error(`Error:003: HTTP error! status: ${response.status}`);
    }

    const contentType = response.headers.get('Content-Type');

    if (!contentType || !contentType.includes('application/json')) {
      throw new Error('Error:001: Expected JSON response but received something else.' + apiUrl);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error:002: occurred during payment processing:', error, ': ' + apiUrl);
    throw error;
  }
};

export default processPayment;