import React from 'react';
import './css/LanguageSelector.css';

const LanguageSelector = ({ onChangeLanguage, selectedLanguage }) => (


  
  <div className="language-selector">
    <select value={selectedLanguage} onChange={(e) => onChangeLanguage(e.target.value)}>
      <option value="en">English</option>
      <option value="sv">Svenska</option>
    </select>
  </div>
);

export default LanguageSelector;