import React from 'react';

// Component to handle registration request
const RegisterUser = async (userData) => {
    const postRequestString = `${process.env.REACT_APP_WEBSERVICE_URL}${process.env.REACT_APP_WEBSERVICE_VERSION}${process.env.REACT_APP_WEBSERVICE_REGISTER}`;

    try {
        const response = await fetch(postRequestString, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(userData),
        });

        return response;
    } catch (error) {
        console.error('Error during registration:', error);
        throw new Error('An unexpected error occurred. Please try again later.');
    }
};

export default RegisterUser;