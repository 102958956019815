import React from 'react';
import ProductFetcher from './ProductFetcher';
import ProductDetail from './ProductDetail_2';
import Slider from '../utils/Slider';
import ProductList from './ProductsList';

const ProductSection = ({
  translations = {},
  selectedProduct,
  productData,
  handleProductData,
  setSelectedProduct,
  language,
  items = [],
  handleProductClick,
  onAddToCart,
}) => {

  const productAvailability = {
    "Mugs": true,
    "FlyersandPrintedAdvertising": true,
    "MobieCover": true,
    "BusinessCards": true,

  };

  const comingSoonText = translations["Coming soon"] || 'Coming soon';

  return (
    <main>
      {/* Coming Soon Section */}
      <div className="coming-soon">
        {comingSoonText}
      </div>

      {/* Product Detail View */}
      {selectedProduct ? (
        <>
          {/* Fetch the product data when a product is selected */}
          <ProductFetcher
            productKey={selectedProduct}
            onProductData={handleProductData}
          />

          {/* Show the product details when the product data is available */}
          {productData && (
            <ProductDetail
              productDetail={productData}
              onBack={() => setSelectedProduct(null)}
              onAddToCart={onAddToCart}
            />
          )}
        </>
      ) : (
        <>
          {/* Slider Component */}
          <Slider
            language={language}
            onAddToCart={onAddToCart}
          />

          {/* Product List View */}
          <ProductList
            items={items}
            translations={translations}
            onProductClick={handleProductClick}
            productAvailability={productAvailability}
          />
        </>
      )}
    </main>
  );
};

export default ProductSection;
