const ContentList = async () => {
  try {
    const response = await fetch(
      process.env.REACT_APP_WEBSERVICE_URL +
      process.env.REACT_APP_WEBSERVICE_VERSION +
      process.env.REACT_APP_WEBSERVICE_GET_CONTENT_LIST
    );

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }

    const data = await response.json();
    console.log(JSON.stringify(data));

    if (!Array.isArray(data.items)) {
      throw new Error('Items is not an array or does not exist');
    }

    const resultArray = data.items
      .filter(item => item.contentType === "BOX") // Filter items where contentType is "BOX"
      .map(item => ({
        id: item.id,
        language: item.language || null,
        name: item.description?.name || null,
        description: item.description || null,
        friendlyUrl: item.friendlyUrl || null,
        keyWords: item.keyWords || null,
        highlights: item.highlights || null,
        metaDescription: item.metaDescription || null,
        title: item.title || null,
      }));

    return resultArray;

  } catch (error) {
    console.error('There was a problem with the fetch operation:', error);
    return []; 
  }
};

export default ContentList; // Keep this as default export