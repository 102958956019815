import React, { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom'; // Import Link here
import './css/UserDropdown.css';

const UserDropdown = ({ username, onLogout }) => {
  const userDropdownRef = useRef(null);
  const [isOpen, setIsOpen] = React.useState(false);

  // Close dropdown if clicked outside
  const handleClickOutside = (event) => {
    if (userDropdownRef.current && !userDropdownRef.current.contains(event.target)) {
      setIsOpen(false); // Close user dropdown
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="user-dropdown" ref={userDropdownRef}>
      <button onClick={() => setIsOpen(!isOpen)} className="user-dropdown-button">
        {username} ▼
      </button>

      {isOpen && (
        <ul className="user-dropdown-list">
          <li className="user-dropdown-item"><Link to="/mypage">My Page</Link></li>
          <li className="user-dropdown-item"><Link to="/settings">Settings</Link></li>
          <li className="user-dropdown-item"><Link to="/orders">Orders</Link></li>
          <li className="user-dropdown-item"><button onClick={onLogout}>Logout</button></li>
        </ul>
      )}
    </div>
  );
};

export default UserDropdown;