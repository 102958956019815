// ButtonsPanel.js

import React from 'react';

const ButtonsPanel = ({ downloadImage, sendImageToServer }) => {
  return (
    <div className="visa-buttons">
      <button className="visa-btn visa-download-btn" onClick={downloadImage}>
        Download Design
      </button>
      <button className="visa-btn visa-upload-btn" onClick={sendImageToServer}>
        Upload to Server
      </button>
    </div>
  );
};

export default ButtonsPanel;
