import React, { useRef } from 'react';

const LogoUpload = ({ setLogoImage }) => {
  const logoRef = useRef(null); // Ref to the logo div
  const uploadInputRef = useRef(null); // Ref to the upload input

  // Handle file upload and set background image
  const handleLogoUpload = (event) => {
    const file = event.target.files[0];
    
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // Update the logo div's background directly via ref
        if (logoRef.current) {
          logoRef.current.style.backgroundImage = `url('${reader.result}')`;
          logoRef.current.style.backgroundSize = 'cover'; // Ensure the image covers the div
          logoRef.current.style.backgroundPosition = 'center'; // Center the image
        }
        setLogoImage(reader.result); // Set the image in the parent component
      };
      reader.readAsDataURL(file); // Read the image file
    }
  };

  return (
    <div style={{ textAlign: 'center', marginTop: '20px' }}>
      
      {/* Upload Input */}
      <input
        type="file"
        accept="image/*"
        id="upload"
        hidden
        ref={uploadInputRef}
        onChange={handleLogoUpload}
      />
      <label
        htmlFor="upload"
        className="btn upload"
        onClick={() => uploadInputRef.current.click()} // Triggers the file upload dialog
        style={{
          display: 'block',
          marginTop: '20px',
          padding: '10px 20px',
          backgroundColor: '#007bff',
          color: '#fff',
          cursor: 'pointer',
          borderRadius: '5px',
          textAlign: 'center'
        }}
      >
        Upload logo
      </label>
    </div>
  );
};

export default LogoUpload;