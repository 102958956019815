import React, { useEffect, useState } from 'react';
import ContentList from '../api/Content';
import './css/ContentDisplay.css';
import { Link } from 'react-router-dom';

const ContentDisplay = () => {
  const [contentItems, setContentItems] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchContent = async () => {
      setLoading(true);

      const fallbackData = await fetchFallbackContent();
      if (fallbackData && Array.isArray(fallbackData.items) && fallbackData.items.length > 0) {
        setContentItems(fallbackData.items);
      } else {
        await fetchContentFromAPI();
      }

      setLoading(false);
    };

    const fetchFallbackContent = async () => {
      try {
        const response = await fetch('/data/contentItems.json');
        if (!response.ok) throw new Error('Network response was not ok');
        const fallbackData = await response.json();
        return fallbackData;
      } catch (localError) {
        console.error('Error fetching content from local file:', localError);
        return null;
      }
    };

    const fetchContentFromAPI = async () => {
      try {
        const data = await ContentList();
        if (data && Array.isArray(data) && data.length > 0) {
          setContentItems(data);
        } else {
          console.log("No content available from API.");
          setContentItems([]);
        }
      } catch (apiError) {
        console.error('Error fetching content from API:', apiError);
        setError(true);
        setContentItems([]);
      }
    };

    fetchContent();
  }, []);

  if (loading) {
    return <div className="content-display-loader">Loading content...</div>;
  }

  if (contentItems.length < 3) {
    return (
      <div className="content-display-container">
        <p>{contentItems.length === 0 ? 'No name available' : 'Coming soon'}</p>
      </div>
    );
  }

  return (
    <div className="content-display-container">
      <ul className="content-display-list">
        {contentItems.map(item => (
          item.description?.name ? (
            <li key={item.id} className="content-display-list-item">
              {item.COMING_SOON ? (
                'Coming soon'
              ) : (
                <Link to={`/containspages${item.description.friendlyUrl}`} className="content-display-link">
                  {item.description.name}
                </Link>
              )}
            </li>
          ) : null
        ))}
      </ul>
    </div>
  );
};

export default ContentDisplay;
