import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import ChargeForm from './ChargeForm';

const stripePromise = loadStripe('pk_test_51QBHsKJpC1mHsYMGgp1ndrBYOR4GlB6I0uUS7msPxigmEf6be9cuU2SECuFQKi3fBNRxduTDe5t0w4nzGvdxY0Im00Y42bRJCm');

const WrappedChargeForm = ({ userData, code }) => { 

  return (
    <Elements stripe={stripePromise}>
      
      <ChargeForm userData={userData} code={code} />
    </Elements>
  );
};

export default WrappedChargeForm;