// CardPreview.js
import React from 'react';
import Draggable from 'react-draggable';

const CardPreview = ({
  backgroundImage, logoImage, businessName, slogan, website, email,
  phoneNumber, streetName, postCode, city, fontColor, fontFamily, cardRef
}) => {
  return (
    <div className="visa-card-container">
      <div
        className="visa-card"
        style={{ backgroundImage: backgroundImage ? `url(${backgroundImage})` : 'none' }}
        ref={cardRef}
      >
        {/* Draggable Logo */}
        <Draggable>
          <div
            className="visa-logo"
            style={{
              backgroundImage: logoImage ? `url(${logoImage})` : 'none',
              backgroundSize: 'cover',
            }}
          ></div>
        </Draggable>

        <div className="visa-business-info" style={{ color: fontColor, fontFamily: fontFamily }}>
          {/* Draggable Business Name */}
          <Draggable>
            <h2 className="visa-business-name">{businessName || 'Your Business Name'}</h2>
          </Draggable>

          {/* Draggable Slogan */}
          <Draggable>
            <p className="visa-slogan">{slogan || 'Your Slogan'}</p>
          </Draggable>

          {/* Draggable Website */}
          <Draggable>
            <p className="visa-website-link">{website || 'yourwebsite.com'}</p>
          </Draggable>

          {/* Draggable Email */}
          <Draggable>
            <p className="visa-email">{email || 'your.email@example.com'}</p>
          </Draggable>

          {/* Draggable Phone Number */}
          <Draggable>
            <p className="visa-phone-number">{phoneNumber || '123-456-7890'}</p>
          </Draggable>

          {/* Address Fields */}
          <div className="visa-address-section">
            <Draggable>
              <p className="visa-street-name">{streetName || 'Your Street Name'}</p>
            </Draggable>

            <Draggable>
              <div className="visa-post-code-city">
                <p className="visa-post-code">{postCode || 'Your Post Code'}</p>
                <p className="visa-city">{city || 'Your City'}</p>
              </div>
            </Draggable>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardPreview;
