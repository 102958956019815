import React, { useState, useEffect, useCallback } from 'react';
import ProductFetcher from '../api/ProductService';
import VisitingCardDesign from '../designPage/VisitCard/VisitCardDesign';
import FlyerDesign from '../designPage/flyer/FlyerDesign';
import MugComponent from '../designPage/mug/MugDesign';
import CylindricalImageCanvasV1 from '../designPage/curved/CylindricalImageCanvasV1';
import IphoneCoverDesign from '../designPage/covers/iphone15/IphoneCoverDesign';

const ProductDisplay = ({ onProductFetched, productKey, setProductKey }) => {
  const [productData, setProductData] = useState(null);
  const [debouncedProductKey, setDebouncedProductKey] = useState(productKey);

  // Update debounced product key
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedProductKey(productKey);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [productKey]);

  // Handle fetched product data
  const handleProductData = useCallback(
    (data) => {
      const parsedData = JSON.parse(data);
      setProductData(parsedData);
      onProductFetched(parsedData);
    },
    [onProductFetched]
  );

  return (
    <div>
      {/* Display the current productKey */}
      <p><strong>{productKey}</strong></p>

      <input
        type="text"
        value={productKey}
        onChange={(e) => setProductKey(e.target.value)}
        placeholder="Enter product key"
      />

      <button onClick={() => setDebouncedProductKey(productKey)}>Click Me</button>

      <ProductFetcher
        productKey={debouncedProductKey}
        onProductData={handleProductData}
      />

      {productData ? (
        <div>
          <h2>{productData.categories[0].description.name}</h2>
          <p>{productData.categories[0].description.description}</p>
        </div>
      ) : (
        <p>Loading...</p>
      )}

      {/* Embed the appropriate design component based on productKey */}

{productKey === 'businesscards' ? (
    <VisitingCardDesign />
) : productKey === 'flyersandprintedadvertising' ? (
    <IphoneCoverDesign />
) : productKey === 'mugs' ? (
    < CylindricalImageCanvasV1/>
) : (
    <IphoneCoverDesign />
)}
    </div>
  );
};

export default ProductDisplay;
