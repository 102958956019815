import React from 'react';
import './css/ProductList.css';

const ProductList = ({ items, translations, onProductClick, productAvailability }) => {
  return (
    <ul className="product-list">
      {items.map((key) => {
        const cleanedKey = key.replace(/\s+/g, ''); // Clean the key for matching

        // Check if the product is available in the productAvailability object
        const isAvailable = productAvailability[cleanedKey] || false;

        return (
          <li
            className="product-list-item"
            key={cleanedKey} // Use cleanedKey as the key to avoid issues with spaces
            id={`product-${cleanedKey}`}
            onClick={() => {
              if (isAvailable) {
                console.log('Product clicked:', cleanedKey);
                onProductClick(cleanedKey); // Proceed with the product click action
              } else {
                alert(`${translations[key]} is not available at the moment.`+cleanedKey); // Show alert if unavailable
              }
            }}
          >
            {translations[key]}
          </li>
        );
      })}
    </ul>
  );
};

export default ProductList;
