import React from 'react';
import { useParams, Link } from 'react-router-dom'; // Import Link for navigation

const ProductDetailNew = ({ selectedProduct, productData, language, onBack }) => {
  const { productId } = useParams(); // Get the productId from the route

  // Fallback text in case productData is not available
  const defaultText = language === 'en' ? 'Product Details' : 'Detalles del Producto';

  return (
    <div className="product-detail">
      <h1>{defaultText}</h1>

      <p>
        {language === 'en'
          ? `Displaying details for product: ${productId}`
          : `Mostrando detalles para el producto: ${productId}`}
      </p>

      {/* Back to Products button */}
      <button onClick={onBack}>
        {language === 'en' ? 'Back to Products' : 'Volver a Productos'}
      </button>

      {/* Display product data if available */}
      {productData ? (
        <div>
          <h3>{productData.name}</h3>
          <img src={productData.imageUrl} alt={productData.name} />
          <p>{productData.description}</p>
        </div>
      ) : (
        <p>{language === 'en' ? 'Loading product details...' : 'Cargando detalles del producto...'}</p>
      )}

      {/* Add a "Home" link to navigate back to the main page */}
      <Link to="/" className="home-link">
        {language === 'en' ? 'Go back to Home' : 'Volver a Inicio'}
      </Link>
    </div>
  );
};

export default ProductDetailNew;