import React, { useState, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import ErrorBoundary from './ErrorBoundary';
import Header from './components/header/Header';
import Main from './components/Main';
import Footer from './components/Footer';
import './App.css';
import Crypto from './components/utils/Crypto';
import AddItem from './components/cart/NAddItem';
import ReduceItem from './components/cart/NReduceItem';
import DeleteItem from './components/cart/DeleteItem';




function App() {
  const [cart, setCart] = useState(() => {
    const savedCart = Crypto.decrypt(localStorage.getItem('cart'));
    console.log('Saved Cart:', JSON.stringify(savedCart));
    return savedCart ? JSON.parse(savedCart) : [];
  });

  const [language, setLanguage] = useState('sv');

  useEffect(() => {
    if (cart.length) {
      localStorage.setItem('cart', Crypto.encrypt(JSON.stringify(cart)));
    } else {
      localStorage.removeItem('cart');
    }
  }, [cart]);

  const handleChangeLanguage = (lang) => {
    setLanguage(lang);
  };

  const { handleAddToCart } = AddItem({ cart, setCart });
  const { handleReduceItem } = ReduceItem({ cart, setCart });
  const { handleDeleteItem } = DeleteItem({ cart, setCart });

  return (
    <ErrorBoundary>
      <BrowserRouter>
        <Header
          language={language}
          onChangeLanguage={handleChangeLanguage}
          selectedLanguage={language}
          cart={cart}
          onDeleteItem={handleDeleteItem}
          onReduceItem={handleReduceItem}
        />
        <Main
          language={language}
          onAddToCart={handleAddToCart}
          cart={cart}
          onDeleteItem={handleDeleteItem}
          onReduceQuantity={handleReduceItem}
        />
        <Footer language={language} />
      </BrowserRouter>
    </ErrorBoundary>
  );
}

export default App;
