import React, { useState } from 'react';
import ShowCart from './ShowCart';

const DropdownCart = ({ cart, onDeleteItem, onReduceItem }) => {
  const [isCartVisible, setCartVisible] = useState(false);
console.log("Martin::dropDownCart");
  // Toggle cart visibility when the link is clicked
  const toggleCartVisibility = () => {
    setCartVisible(!isCartVisible);
  };

  return (
    <div className="dropdown-cart">
      {/* Dropdown link to toggle cart visibility */}
      <button onClick={toggleCartVisibility} className="cart-dropdown-link">
        My Cart ({cart.length} items)
      </button>

      {/* Conditional rendering of the cart */}
      {isCartVisible && (
        <div className="cart-dropdown-content">
          <ShowCart 
            cart={cart} 
            onDeleteItem={onDeleteItem} 
            onReduceItem={onReduceItem} 
          />
        </div>
      )}
    </div>
  );
};

export default DropdownCart;