import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';

const useGetProfile = () => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    const apiUrl = `${process.env.REACT_APP_WEBSERVICE_URL}${process.env.REACT_APP_WEBSERVICE_VERSION}${process.env.REACT_APP_VEBSERVICE_GET_PROFILE}`;

    useEffect(() => {
        const fetchData = async () => {
            const token = Cookies.get('token'); // Use your cookie name here
            console.log('GetProfile::Fetching data...');
            console.log('GetProfile::API URL:', apiUrl);
            console.log('GetProfile::Using token:', token); // This should now log your token

            if (!token) {
                setError('No authentication token found');
                return;
            }

            try {
                const response = await fetch(apiUrl, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const responseData = await response.json();
                console.log('GetProfile::API response received:', responseData);
                setData(responseData);
            } catch (err) {
                console.error('GetProfile::Error fetching profile:', err);
                setError(err.message);
            }
        };

        fetchData();
    }, [apiUrl]);

    return { data, error };
};

export default useGetProfile;