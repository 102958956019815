// src/components/Crypto.js
import CryptoJS from 'crypto-js';

const SECRET_KEY = 'ItISMySecureKey'; // Change this to your secure key

const Crypto = {
  // Encrypt a given string
  encrypt(text) {
    if (!text) {
      throw new Error("Nothing to encrypt");
    }
    const encryptedData = CryptoJS.AES.encrypt(text, SECRET_KEY).toString();
    return encryptedData;
  },

  // Decrypt a given string
  decrypt(cipherText) {
    if (!cipherText) {
      console.error("No ciphertext provided for decryption");
      return null; // Return null if there's nothing to decrypt
    }

    const bytes = CryptoJS.AES.decrypt(cipherText, SECRET_KEY);
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    
    // Check if decryption was successful
    if (!decryptedData) {
      console.error("Decryption failed or returned an invalid value");
      return null; // Return null if decryption was unsuccessful
    }

    return decryptedData;
  },
};

// Export the Crypto object for usage
export default Crypto;