import React, { useState, useRef } from 'react';
import html2canvas from 'html2canvas';
import Draggable from 'react-draggable';
import './VisitCard.css';

import CardPreview from './CardPreview';
import InputSection from './InputSection';
import SettingsPanel from './SettingsPanel';
import ButtonsPanel from './ButtonsPanel';

const VisitingCardDesign = () => {
    // State hooks for images, input fields, and font settings
    const [logoImage, setLogoImage] = useState(null);
    const [backgroundImage, setBackgroundImage] = useState(null);
    const [businessName, setBusinessName] = useState('');
    const [slogan, setSlogan] = useState('');
    const [website, setWebsite] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [streetName, setStreetName] = useState('');
    const [postCode, setPostCode] = useState('');
    const [city, setCity] = useState('');
    const [fontColor, setFontColor] = useState('black');
    const [fontFamily, setFontFamily] = useState('Roboto');

    const cardRef = useRef(null);

    // Function to download the card image
    const downloadImage = () => {
        html2canvas(cardRef.current).then((canvas) => {
            const link = document.createElement('a');
            link.href = canvas.toDataURL('image/jpeg');
            link.download = 'visiting_card.jpg';
            link.click();
        });
    };

    // Function to upload the card image to the server
    const sendImageToServer = () => {
        html2canvas(cardRef.current).then((canvas) => {
            const imageData = canvas.toDataURL('image/jpeg');
            const byteString = atob(imageData.split(',')[1]);
            const mimeString = imageData.split(',')[0].split(':')[1].split(';')[0];

            const ab = new ArrayBuffer(byteString.length);
            const ia = new Uint8Array(ab);
            for (let i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }

            const blob = new Blob([ab], { type: mimeString });
            const formData = new FormData();
            formData.append('file', blob, 'visiting_card.jpg');

            fetch('/upload', {
                method: 'POST',
                body: formData,
            })
            .then((response) => response.json())
            .then((data) => {
                console.log('Successfully uploaded:', data);
                alert('Image successfully uploaded to the server!');
            })
            .catch((error) => {
                console.error('Error uploading the image:', error);
            });
        });
    };

    // Function to handle background image upload
    const handleBackgroundUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setBackgroundImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <div className="app">
            <h1 className="heading"><span>Visiting Card Design</span></h1>

            <div className="main">
                {/* Card Preview Section */}
                <div className="card-container">
                    <CardPreview
                        cardRef={cardRef}
                        backgroundImage={backgroundImage}
                        logoImage={logoImage}
                        businessName={businessName}
                        slogan={slogan}
                        website={website}
                        email={email}
                        phoneNumber={phoneNumber}
                        streetName={streetName}
                        postCode={postCode}
                        city={city}
                        fontColor={fontColor}
                        fontFamily={fontFamily}
                    />
                </div>

                {/* Settings Panel Section */}
                <div className="settings-panel">
                    <SettingsPanel
                    fontColor={fontColor}
                    setFontColor={setFontColor}
                    fontFamily={fontFamily}
                    setFontFamily={setFontFamily}
                        setBackgroundImage={setBackgroundImage}
                        setLogoImage={setLogoImage}
                        handleBackgroundUpload={handleBackgroundUpload}
                    />
                </div>

                {/* Input Section for User Info */}
                <InputSection
                    businessName={businessName}
                    setBusinessName={setBusinessName}
                    slogan={slogan}
                    setSlogan={setSlogan}
                    website={website}
                    setWebsite={setWebsite}
                    email={email}
                    setEmail={setEmail}
                    phoneNumber={phoneNumber}
                    setPhoneNumber={setPhoneNumber}
                    streetName={streetName}
                    setStreetName={setStreetName}
                    postCode={postCode}
                    setPostCode={setPostCode}
                    city={city}
                    setCity={setCity}
                    fontColor={fontColor}
                    setFontColor={setFontColor}
                    fontFamily={fontFamily}
                    setFontFamily={setFontFamily}
                />

                {/* Buttons Panel for Download & Upload */}
                <ButtonsPanel
                    downloadImage={downloadImage}
                    sendImageToServer={sendImageToServer}
                />
            </div>
        </div>
    );
};

export default VisitingCardDesign;
