import React from 'react';

const InputSection = ({
  businessName, setBusinessName, slogan, setSlogan, website, setWebsite,
  email, setEmail, phoneNumber, setPhoneNumber, streetName, setStreetName,
  postCode, setPostCode, city, setCity
}) => {
  return (
    <div className="visa-inputs">

      <div className="visa-input-group">
        <label>Business Name:</label>
        <input
          type="text"
          className="visa-name"
          maxLength="30"
          placeholder="Business Name"
          value={businessName}
          onChange={(e) => setBusinessName(e.target.value)}
        />
      </div>

      <div className="visa-input-group">
        <label>Phone Number:</label>
        <input
          type="text"
          className="visa-phone"
          placeholder="Phone Number"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
        />
      </div>

      <div className="visa-input-group">
        <label>Street Name:</label>
        <input
          type="text"
          className="visa-street-name"
          placeholder="Street Name"
          value={streetName}
          onChange={(e) => setStreetName(e.target.value)}
        />
      </div>

      <div className="visa-input-group">
        <label>Post Code:</label>
        <input
          type="text"
          className="visa-post-code"
          placeholder="Post Code"
          value={postCode}
          onChange={(e) => setPostCode(e.target.value)}
        />
      </div>

      <div className="visa-input-group">
        <label>City:</label>
        <input
          type="text"
          className="visa-city"
          placeholder="City"
          value={city}
          onChange={(e) => setCity(e.target.value)}
        />
      </div>

      <div className="visa-input-group">
        <label>Slogan:</label>
        <input
          type="text"
          className="visa-slogan"
          placeholder="Your Slogan"
          value={slogan}
          onChange={(e) => setSlogan(e.target.value)}
        />
      </div>

      <div className="visa-input-group">
        <label>Website:</label>
        <input
          type="text"
          className="visa-website"
          placeholder="Website URL"
          value={website}
          onChange={(e) => setWebsite(e.target.value)}
        />
      </div>

      <div className="visa-input-group">
        <label>Email:</label>
        <input
          type="email"
          className="visa-email"
          placeholder="Email Address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>

    </div>
  );
};

export default InputSection;
