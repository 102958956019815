import React from 'react';

const PersonalInformationTable = ({ userData, handleConfirmAddresses }) => {
  return (
    <div>
      <h2>Personal Information</h2>
      <table border="1" cellPadding="10">
        <thead>
          <tr>
            <th>Field</th>
            <th>Billing Info</th>
            <th>Shipping Info</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>First Name</td>
            <td>{userData.billing.firstName}</td>
            <td>{userData.delivery.firstName}</td>
          </tr>
          <tr>
            <td>Last Name</td>
            <td>{userData.billing.lastName}</td>
            <td>{userData.delivery.lastName}</td>
          </tr>
          <tr>
            <td>Company</td>
            <td>{userData.billing.company}</td>
            <td>{userData.delivery.company}</td>
          </tr>
          <tr>
            <td>Phone</td>
            <td>{userData.billing.phone}</td>
            <td>{userData.delivery.phone}</td>
          </tr>
          <tr>
            <td>Address</td>
            <td>
              {`${userData.billing.address}, ${userData.billing.city}, 
              ${userData.billing.stateProvince}, ${userData.billing.country}, 
              ${userData.billing.postalCode}`}
            </td>
            <td>
              {`${userData.delivery.address}, ${userData.delivery.city}, 
              ${userData.delivery.stateProvince}, ${userData.delivery.country}, 
              ${userData.delivery.postalCode}`}
            </td>
          </tr>
          <tr>
            <td>Email</td>
            <td>{userData.emailAddress}</td>
            <td>{userData.emailAddress}</td>
          </tr>
        </tbody>
      </table>

      <button onClick={handleConfirmAddresses}>Confirm Addresses</button>
    </div>
  );
};

export default PersonalInformationTable;