import React from 'react';
import { globalVariable } from '../../../utils/globals';

const AddItemAPI = () => {

  const apiUrl = globalVariable.ApiBaseUri + process.env.REACT_APP_VEBSERVICE_NEW_ITEM;

  const handleAddToCart = async (item) => {

    const itemApi = { product: item.id, quantity: 1 };

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(itemApi),
      });

      if (!response.ok) {
        throw new Error('Failed to add item to the cart');
      }

      const data = await response.json(); 
      const responseData ={id: item.id, code:data.code, price: item.price, product: data.products.find(product=>product.shu === itemApi.product)}
      console.log('Martin::AddingAPI: data: ', data);
      console.log('Martin::AddingAPI: data: ', responseData);
      return responseData;
    //  return data; 
    } catch (error) {
      console.error('Error adding item to cart:', error);
      throw error; // Propagating the error to the caller
    }
  };

  return { handleAddToCart }; // Just returning the API handler
};

export default AddItemAPI;