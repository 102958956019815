import React from 'react';
import AddItemAPI from '../api/cart/AddItemAPI'; 
const AddItem = ({ cart, setCart }) => {
  const { handleAddToCart: handleAddToCartAPI } = AddItemAPI(); 
  const handleAddToCart = async (item) => {
    console.log('Martin::AddItem: item:', item);

    try {
      const data = await handleAddToCartAPI(item);

      console.log('Martin::AddItem: API response:');

      setCart((prevCart) => {
        const existingItem = prevCart.find((cartItem) => cartItem.id === data.id);

        if (existingItem) {
          return prevCart.map((cartItem) =>
            cartItem.id === data.id
              ? { ...cartItem, quantity: cartItem.quantity + 1 }
              : cartItem
          );
        } else {
          return [...prevCart, { ...data, quantity: 1 }];
        }
      });
    } catch (error) {
      console.error('Error adding item to cart:', error);
    }
  };

  return { handleAddToCart }; 
};

export default AddItem;