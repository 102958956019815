import React, { useEffect, useState } from 'react';
import useGetProfile from '../api/GetProfile';
import LoginForm from '../utils/login/LoginForm';
import Register from '../utils/register/Register';
import WrappedChargeForm from '../payment/WrappedChargeForm';
import PersonalInformationTable from './PersonalInformationTable';
import './css/PersonalInfo.css';

const PersonalInfo = ({ isUserLoggedIn, code }) => { 
console.log('PersonalInfo::code:', code);
  const { data: userData, error: apiError } = useGetProfile();
  const [loading, setLoading] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false); 

  useEffect(() => {
    if (isUserLoggedIn) {
      setLoading(true);
    }
  }, [isUserLoggedIn]);

  useEffect(() => {
    if (userData) {
      setLoading(false);
    }
  }, [userData]);

  const handleConfirmAddresses = () => {
    setIsConfirmed(true); // Confirm the addresses
  };

  if (loading) {
    return <div><h2>Loading user data...</h2></div>;
  }

  if (apiError) {
    console.error('PersonalInfo::Error fetching user data:', apiError.message);
    return <div><h2>Error</h2><p>{apiError.message}</p></div>;
  }

  if (!isUserLoggedIn) {
    return (
      <div className="split-container">
        <div className="split left">
          <h2>Login</h2>
          <LoginForm />
        </div>
        <div className="split right">
          <Register onRegister={() => {}} />
        </div>
      </div>
    );
  }

  if (userData) {
    if (isConfirmed) {
      // Pass userData and itemId to WrappedChargeForm
      return <WrappedChargeForm userData={userData} code={code} />;
    }

    return (
      <PersonalInformationTable 
        userData={userData} 
        handleConfirmAddresses={handleConfirmAddresses} 
      />
    );
  }

  return (
    <div>
      <h2>Personal Information...</h2>
      <p>This is the content of the PersonalInfo component.</p>
      <p>Waiting for user data...</p>
    </div>
  );
};

export default PersonalInfo;