import React from 'react';
import './css/UserDataDisplay.css';
const UserDataDisplay = ({ userData }) => {
  return (
    <div>
      <h2>User Data</h2>
      {userData ? (
        <div className="user-data">
          <p><strong>First Name:</strong> {userData.billing?.firstName || 'N/A'}</p>
          <p><strong>Last Name:</strong> {userData.billing?.lastName || 'N/A'}</p>
          <p><strong>Company:</strong> {userData.billing?.company || 'N/A'}</p>
          <p><strong>Phone:</strong> {userData.billing?.phone || 'N/A'}</p>
          <p><strong>Billing Address:</strong> 
            {`${userData.billing?.address || 'N/A'}, ${userData.billing?.city || ''}, ${userData.billing?.stateProvince || ''}, ${userData.billing?.country || ''}, ${userData.billing?.postalCode || ''}`}
          </p>
          <p><strong>Email:</strong> {userData.emailAddress || 'N/A'}</p>

          <h3>Shipping Info</h3>
          <p><strong>First Name:</strong> {userData.delivery?.firstName || 'N/A'}</p>
          <p><strong>Last Name:</strong> {userData.delivery?.lastName || 'N/A'}</p>
          <p><strong>Company:</strong> {userData.delivery?.company || 'N/A'}</p>
          <p><strong>Phone:</strong> {userData.delivery?.phone || 'N/A'}</p>
          <p><strong>Shipping Address:</strong> 
            {`${userData.delivery?.address || 'N/A'}, ${userData.delivery?.city || ''}, ${userData.delivery?.stateProvince || ''}, ${userData.delivery?.country || ''}, ${userData.delivery?.postalCode || ''}`}
          </p>
        </div>
      ) : (
        <p>No user data available.</p>
      )}
    </div>
  );
};

export default UserDataDisplay;