import React from 'react';
import DeleteItemAPI from '../api/cart/DeleteItemAPI';

const DeleteItem = ({ cart, setCart }) => {
    const { handleDeleteItem: handleDeleteItemAPI } = DeleteItemAPI();

    const handleDeleteItem = async (id) => {
        try {
            console.log('DeleteItem.js..Delete from cart:', id);
            const itemToDelete = cart.find((item) => item.id === id);

            if (itemToDelete) {
                const result = await handleDeleteItemAPI(itemToDelete);

                if (result.success) {
                    setCart((prevCart) => prevCart.filter((item) => item.id !== itemToDelete.id));
                } else {
                    console.error('Failed to delete item from API:', result.error);
                }
            }
        } catch (error) {
            console.error('Error deleting item from cart:', error);
        }
    };

    return { handleDeleteItem };
};

export default DeleteItem;