import React, { useRef, useState, useEffect } from 'react';
import './CylindricalImageCanvasV1.css'; // Import the updated CSS file

function CylindricalImageCanvasV1({
  productImgSrc,
  overlayImgSrc,
  initialXOffset = 0,
  initialYOffset = 0,
  a = 75, // Half-width of the ellipse
  b = 10, // Curve roundness
  scaleFactorDivisor = 4, // Default scale factor
}) {
  const canvasRef = useRef(null);
  const [overlayImg, setOverlayImg] = useState(overlayImgSrc);

  // Handle the overlay image file input
  const handleOverlayImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setOverlayImg(URL.createObjectURL(file)); // Update overlay image
    }
  };

  // Draw the wrap effect on the canvas
  useEffect(() => {
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');

      // Load product image as the default
      const productImg = new Image();
      productImg.src = productImgSrc;
      productImg.onload = () => {
        canvas.width = productImg.width;
        canvas.height = productImg.height;

        // Draw product image on canvas
        ctx.drawImage(productImg, 0, 0, productImg.width, productImg.height);

        // Apply cylindrical wrap effect with overlay image
        if (overlayImg) {
          const overlayImage = new Image();
          overlayImage.src = overlayImg;
          overlayImage.onload = () => {
            const iw = overlayImage.width;
            const ih = overlayImage.height;
            const scaleFactor = iw / (scaleFactorDivisor * a); // Scaling factor

            // Apply cylindrical distortion effect on the overlay image
            for (let X = 0; X < iw; X++) {
              const y = (b / a) * Math.sqrt(a * a - (X - a) * (X - a)); // Cylindrical effect

              // Draw overlay image with cylindrical distortion and position adjustments
              ctx.drawImage(
                overlayImage,
                X * scaleFactor, // Scale the X position
                0, // Top of the image
                iw / 10, // A small slice of the image
                ih, // Full height of the overlay image
                X + initialXOffset, // X offset for position (down and right)
                y + initialYOffset, // Y offset for position (down and right)
                1, // The width of the vertical slice (small)
                174 // The height of the vertical slice
              );
            }
          };
        }
      };
    }
  }, [productImgSrc, overlayImg, initialXOffset, initialYOffset, a, b, scaleFactorDivisor]);

  return (
    <div className="cylindrical-image-container">
      <canvas ref={canvasRef}></canvas>
      <div className="cylindrical-image-controls">
        <label>
          Upload Overlay Image:
          <input type="file" onChange={handleOverlayImageChange} />
        </label>
      </div>
    </div>
  );
}

// Usage of CylindricalImageCanvasV1 with initial parameters
function App() {
  return (
    <CylindricalImageCanvasV1
      productImgSrc="/assets/images/system/left_handle_cup_i7ztfs.jpg" // Product image path
      overlayImgSrc={null} // Initially no overlay image
      initialXOffset={100} // Move the overlay image 50px left
      initialYOffset={110} // Move the overlay image 100px down
      a={75} // Half-width of the ellipse
      b={10} // Curve roundness
      scaleFactorDivisor={4} // Default scale factor
    />
  );
}

export default App;
