import React from 'react';
import ReduceItemAPI from '../api/cart/ReduceItemAPI';

const ReduceItem = ({ cart, setCart }) => {
  const { handleReduceItem: handleReduceItemAPI } = ReduceItemAPI();

  const handleReduceItem = async (id) => {
    try {
      const updatedCart = await Promise.all(
        cart.map(async (item) => {
          if (item.id === id && item.quantity > 1) {
            await handleReduceItemAPI({
              product: item.id,
              quantity: item.quantity - 1,
              code: item.code,
            });
            return { ...item, quantity: item.quantity - 1 };
          }
          return item;
        })
      );

      setCart(updatedCart);
    } catch (error) {
      console.error('Error reducing item quantity:', error);
    }
  };

  return { handleReduceItem };
};

export default ReduceItem;