// src/utils/login/LoginForm.js

import React from 'react';
import LoginFormInputs from './LoginFormInputs';
import LoginMessages from './LoginMessages';

const LoginForm = ({ username, password, setUsername, setPassword, handleSubmit, loginError, successMessage }) => {
  
    return (
        <form onSubmit={handleSubmit}>
            <LoginFormInputs
                username={username}
                password={password}
                setUsername={setUsername} // Pass setUsername correctly
                setPassword={setPassword} // Pass setPassword correctly
            />
            <LoginMessages loginError={loginError} successMessage={successMessage} />
        </form>
    );
};

export default LoginForm; // Ensure this is a default export