import React, { useState } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js'; 
import processPayment from '../api/PaymentService';
import PaymentForm from './PaymentForm'; 
import UserDataDisplay from './UserDataDisplay';
import './css/ChargeForm.css';
import {loadStripe} from '@stripe/stripe-js';


// Load your publishable key
const stripePromise = loadStripe('pk_test_51QBHsKJpC1mHsYMGgp1ndrBYOR4GlB6I0uUS7msPxigmEf6be9cuU2SECuFQKi3fBNRxduTDe5t0w4nzGvdxY0Im00Y42bRJCm');

const ChargeForm = ({ userData, code }) => { // Accept code as a prop

  const [email, setEmail] = useState(userData?.emailAddress || '');
  const [isProcessing, setIsProcessing] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      alert('Stripe is not loaded yet. Please wait.');
      return;
    }

    setIsProcessing(true);

    const result = await stripe.createToken(elements.getElement(CardElement)); 

    if (result.error) {
      console.error(result.error.message);
      alert(result.error.message);
      setIsProcessing(false);
      return;
    }

    const { token } = result;

    const requestBody = {
      customer: {
        emailAddress: email,
        billing: {
          address: userData?.billing?.address || '',
          city: userData?.billing?.city || '',
          postalCode: userData?.billing?.postalCode || '',
          country: userData?.billing?.country || '',
          zone: userData?.billing?.zone || '',
          firstName: userData?.billing?.firstName || '',
          lastName: userData?.billing?.lastName || '',
        },
      },
      paymentToken: token.id, 
      card: result, 
      code: code, // Include code in the JSON request body
    };

    try {
      // Call processPayment with requestBody and code as separate arguments
      const data = await processPayment(requestBody, code); // code as argument
      console.log('Charge response:', data);
      alert(data.details);
    } catch (error) {
      alert('An error occurred while processing the payment. Please try again.'+error);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div>
      <UserDataDisplay userData={userData} />

      <PaymentForm
        email={email}
        setEmail={setEmail}
        handleSubmit={handleSubmit}
        isProcessing={isProcessing}
        stripe={stripe}
        elements={elements}
        code={code} // Pass code to PaymentForm
      />
    </div>
  );
};

export default ChargeForm;