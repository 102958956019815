import React, { useEffect, useState } from 'react';
import './css/Footer.css';

const Footer = ({ language }) => {
  const [translations, setTranslations] = useState({});

  useEffect(() => {
    const fetchTranslations = async () => {
      const url = `/languages/${language === 'sv' ? 'swedish' : 'english'}.json`;
      try {
        const response = await fetch(url);
        const data = await response.json();
        setTranslations(data);
      } catch (error) {
        console.error('Error fetching translations:', error);
      }
    };

    fetchTranslations();
  }, [language]);

  const footerContentKey = 'Footer Content'; // This should match the key in the JSON file
  const footerContent = translations[footerContentKey] || 'Footer content not found';

  const version = process.env.REACT_APP_VERSION || 'Version not found'; // Reading the version from .env

  return (
    <footer className="footer">
      <div className="footer-content">
        <p>
        PrintlyQuick | Address:  Sweden | Contact: +46 722 08 03 25 | Contact us: <a href="mailto:admininfo@printlysafe.com">admininfo@printlysafe.com</a> | Design by{' '}
          <a href="http://www.jawep.com" target="_blank" rel="noopener noreferrer">
            www.jawep.com
          </a>
        </p>
        <p className="footer-version">Version {version}</p> {/* Display the version */}
      </div>
    </footer>
  );
};

export default Footer;