import React, { useState } from 'react';
import ShowCart from './ShowCart';

const Cart = () => {
  const initialCart = [
    { id: 1, name: 'Product 1', price: 29.99, quantity: 1 },
    { id: 2, name: 'Product 2', price: 19.99, quantity: 2 },
    { id: 3, name: 'Product 3', price: 39.99, quantity: 1 },
  ];

  const [cart, setCart] = useState(initialCart);

  // Handle reducing the quantity of an item
  const handleReduceItem = (id) => {
    console.log('Martin::Reducing item with ID:', id);
    setCart(
      cart.map((item) =>
        item.id === id && item.quantity > 1
          ? { ...item, quantity: item.quantity - 1 }
          : item
      )
    );
  };

  // Handle deleting an item
  const handleDeleteItem = (id) => {
    console.log('Martin::handleDelete item with ID:', id);

    setCart(cart.filter((item) => item.id !== id));
  };

  return (
    <div>
      <h2>My Cart</h2>
      <ShowCart
        cart={cart}
        onDeleteItem={handleDeleteItem}
        onReduceItem={handleReduceItem}
      />
    </div>
  );
};

export default Cart;