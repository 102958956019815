import React from 'react';

const LoginMessages = ({ loginError, successMessage }) => {
  return (
    <div>
      {loginError && <p className="error-message">{loginError}</p>}
      {successMessage && <p className="success-message">{successMessage}</p>}
    </div>
  );
};

export default LoginMessages;