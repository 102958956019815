import React, { useState, useRef, useEffect } from 'react';
import './css/Login.css';
import LoginFormInputs from './LoginFormInputs';
import LoginMessages from './LoginMessages';
import { Link } from 'react-router-dom';

const Login = ({ onLogin }) => {
  console.log("Martin::Login");

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [loginError, setLoginError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  // Ref to detect clicks outside the dropdown
  const dropdownRef = useRef(null);

  // Handle the login submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoginError('');
    setSuccessMessage('');

    const postRequestString = `${process.env.REACT_APP_WEBSERVICE_URL}${process.env.REACT_APP_WEBSERVICE_VERSION}${process.env.REACT_APP_WEBSERVICE_LOGIN}`;

    try {
      const response = await fetch(postRequestString, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password }),
        credentials: 'include',
      });

      if (!response.ok) {
        const errorData = await response.json();
        setLoginError(errorData.message || 'Login failed. Please try again.');
        setIsOpen(true); // Keep dropdown open on failure
      } else {
        const data = await response.json();
        setSuccessMessage('Login successful!');
        setIsOpen(false); // Close dropdown on success

        // Call onLogin from props to update loggedIn state in Header
        if (onLogin) {
          onLogin(username);  // Pass the logged-in username back to Header
        }

        document.cookie = `token=${data.token}; Secure; SameSite=Strict`;
      }
    } catch (error) {
      console.error('Error during login:', error);
      setLoginError('An unexpected error occurred. Please try again later.');
      setIsOpen(true); // Keep dropdown open on failure
    }

    // Clear form fields after login attempt
    setUsername('');
    setPassword('');
  };

  // Dropdown toggle for login
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // Close dropdown when clicking outside
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false); // Close dropdown if clicked outside
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="login-container">
      {!isOpen ? (
        <>
          <button onClick={toggleDropdown} className="login-button">
            Logga in / Bli medlem
          </button>
        </>
      ) : (
        <div className="login-dropdown" ref={dropdownRef}>
          <form onSubmit={handleSubmit}>
            <LoginFormInputs
              username={username}
              password={password}
              setUsername={setUsername}
              setPassword={setPassword}
            />
            <button type="submit">Login</button>

            {/* Display success or error messages */}
            <LoginMessages loginError={loginError} successMessage={successMessage} />
          </form>

          {/* Add the Register link below the form */}
          <div className="register-link">
            Don't have an account? 
            <Link to="/register" onClick={() => setIsOpen(false)}>
              Bli medlem här
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;